import React, {useEffect, useState} from 'react';

import {
  ResponsiveContainer,
  LineChart, Line,
  XAxis, YAxis,
  CartesianGrid, Tooltip,
} from 'recharts';

import {ChartValue} from "../pages/ModuleDetail";
import {chartStepsCount} from "../config";
import {IonRow, IonButton, IonCol, IonIcon} from "@ionic/react";
import {addOutline, removeOutline} from "ionicons/icons";
import {
  getSelectedTimestamp, selectTimestamp,
  getAutoAxis, setAutoAxis,
  getAxisBoundsLeft, setAxisBoundsLeft,
  getAxisBoundsRight, setAxisBoundsRight
} from "../store/peripheralsSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";

type AxisRange = {
  min           : number,
  max           : number,
}

type ModuleChartProps = {
  data          : Array<ChartValue>,
  showRight     : boolean,
  leftRange     : AxisRange,
  rightRange?   : AxisRange,
  leftDecimals  : number,
  rightDecimals : number
};

const ModuleChart: React.FC<ModuleChartProps> = (
  {data, showRight, leftRange, rightRange,
    leftDecimals, rightDecimals}
) => {

  const dispatch = useAppDispatch();

  const autoAxis = useAppSelector(getAutoAxis);
  const axisBoundsLeft = useAppSelector(getAxisBoundsLeft);
  const axisBoundsRight = useAppSelector(getAxisBoundsRight);

  // We'll zoom in/out by 1/zoomPart of current min/max
  const zoomPart = 8;

  const selectedTimestamp = useAppSelector(getSelectedTimestamp);

  // let shownData = Array<ChartValue>();
  const [shownData, setShownData] = useState<Array<ChartValue>>([]);

  useEffect(() => {

    // Graph can be fixed on some timestamp. This timestamp should be in the half of the X axis.
    if (selectedTimestamp !== null) {

      // Find the index of this timestamp in current data set
      const sliceStartIndex = data.findIndex((item) => item.timestamp === selectedTimestamp);

      // How many steps do we want to show on each side of timestamp?
      const halfOfSteps = chartStepsCount / 2;

      // If the selected timestamp is above the highest or below the lowest value in the current dataset
      // (with the offset of half steps count), we will reset the timestamp selection.
      if (sliceStartIndex + halfOfSteps >= data.length || sliceStartIndex - halfOfSteps < 0) {

        // Reset timestamp
        dispatch(selectTimestamp(null));
        // Reset zoom
        dispatch(setAutoAxis({left: true, right: true}));
        dispatch(setAxisBoundsLeft({min: undefined, max: undefined}));
        dispatch(setAxisBoundsRight({min: undefined, max: undefined}));

      } else {

        // Otherwise slice the data
        // shownData = data.slice(sliceStartIndex - halfOfSteps, sliceStartIndex + halfOfSteps);
        setShownData(data.slice(sliceStartIndex - halfOfSteps, sliceStartIndex + halfOfSteps));
      }
    } else {

      // We don't have any timestamp selected, just take the last values
      // shownData = data.slice(chartStepsCount * -1);
      setShownData(data.slice(chartStepsCount * -1));
    }

  }, [selectedTimestamp, data]);

  useEffect(() => {

    shownData.map(item => {
      if (autoAxis.left) {
        if (axisBoundsLeft.min === undefined || item.left < axisBoundsLeft.min) {
          dispatch(setAxisBoundsLeft({min: item.left, max: axisBoundsLeft.max}));
        }

        if (axisBoundsLeft.max === undefined || item.left > axisBoundsLeft.max) {
          dispatch(setAxisBoundsLeft({min: axisBoundsLeft.min, max: item.left}));
        }
      }

      if (item.right && autoAxis.right) {
        if (axisBoundsRight.min === undefined || item.right < axisBoundsRight.min) {
          dispatch(setAxisBoundsRight({min: item.right, max: axisBoundsRight.max}));
        }

        if (axisBoundsRight.max === undefined || item.right > axisBoundsRight.max) {
          dispatch(setAxisBoundsRight({min: axisBoundsRight.min, max: item.right}));
        }
      }
      return null;
    })

  }, [shownData, autoAxis, axisBoundsLeft, axisBoundsRight]);

  useEffect(() => {
    return () => {
      dispatch(setAxisBoundsLeft({min: undefined, max: undefined}));
      dispatch(setAxisBoundsRight({min: undefined, max: undefined}));
      dispatch(setAutoAxis({left: true, right: true}));
      dispatch(selectTimestamp(null));
    }
  }, [])


  function zoomDelta(min: number, max: number, direction: number) {
    return ((max - min) / zoomPart) * direction;
  }

  function zoom(direction: number, axis: string) {

    if (axis === "left") {
      let delta = zoomDelta(axisBoundsLeft.min ?? 0, axisBoundsLeft.max ?? 0, direction);

      dispatch(setAxisBoundsLeft({
        min: (axisBoundsLeft.min ?? 0) + delta,
        max: (axisBoundsLeft.max ?? 0) - delta
      }));
      dispatch(setAutoAxis({left: false, right: autoAxis.right}));

    } else if (axis === "right") {
      let delta = zoomDelta(axisBoundsRight.min ?? 0, axisBoundsRight.max ?? 0, direction);

      dispatch(setAxisBoundsRight({
        min: (axisBoundsRight.min ?? 0) + delta,
        max: (axisBoundsRight.max ?? 0) - delta
      }));
      dispatch(setAutoAxis({left: autoAxis.left, right: false}));
    }
  }

  return (

    <div>
      <IonRow>
        <ResponsiveContainer
          height="50%"
          minHeight={300}
          width="100%">
          <LineChart data={shownData}
                     margin={{top: 10}}>
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="timestamp"
                   axisLine={false}
            />
            <YAxis domain={[axisBoundsLeft.min ?? leftRange.min, axisBoundsLeft.max ?? leftRange.max]}
                   allowDataOverflow={true}
                   mirror={true}
                   padding={{ top: 20, bottom: 20 }}
                   tickFormatter={tick => tick.toFixed(leftDecimals)}
                   yAxisId="left" />
            { (showRight && rightRange) ? (
              <YAxis domain={[axisBoundsRight.min ?? rightRange.min, axisBoundsRight.max ?? rightRange.max]}
                     allowDataOverflow={true}
                     yAxisId="right"
                     mirror={true}
                     padding={{ top: 20, bottom: 20 }}
                     tickFormatter={tick => tick.toFixed(rightDecimals)}
                     orientation="right" />
            ) : ''}
            <Line type="monotone"
                  yAxisId="left"
                  dataKey="left"
                  stroke="#8884d8"
                  activeDot={{ r: 6 }}
                  isAnimationActive={false} />
            { (showRight && rightRange) ? (
            <Line type="monotone"
                  yAxisId="right"
                  dataKey="right"
                  stroke="#FF0000"
                  activeDot={{ r: 6 }}
                  isAnimationActive={false} />
            ) : ''}
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </IonRow>
      <IonRow class="mps-chart-axis-controls">
        <IonCol size="6">
          <IonButton onClick={() => zoom(-1, "left")} color="main">
            <IonIcon icon={removeOutline} aria-label="Zoom out" />
          </IonButton>
          <IonButton onClick={() => zoom(1, "left")} color="main">
            <IonIcon icon={addOutline} aria-label="Zoom in"/>
          </IonButton>
        </IonCol>
        {showRight ? (
          <IonCol size="6" class="ion-text-right">
            <IonButton onClick={() => zoom(-1, "right")} color="main">
              <IonIcon icon={removeOutline} aria-label="Zoom out"/>
            </IonButton>
            <IonButton onClick={() => zoom(1, "right")} color="main">
              <IonIcon icon={addOutline} aria-label="Zoom in"/>
            </IonButton>
          </IonCol>
        ): ''}

      </IonRow>
    </div>
  );
};

export default ModuleChart;
