// @note: this whole file is for easier development purposes only. No application logic here

import {useAppDispatch} from "../store/hooks";
import {addPeripheral, setConnectedPeripheral, updateModuleValue} from "./peripheralsSlice";
import {GraphTypes, SettingTypes} from "../types.d";
import {hexStringToBytes} from "../utils/conversions";
import {ScanResult} from "@capacitor-community/bluetooth-le";

export function useFakeData() {
  const dispatch = useAppDispatch();

  const hydrateFakeData = () => {
    console.log("Hydrating fake data from start");
    dispatch(addPeripheral(fakePeripherals[0]));
    dispatch(setConnectedPeripheral(fakePeripherals[0].id));
  }

  const random = (min : number, max : number, decimalMultiplicator: number) => {
    return Math.round((Math.random() * (max - min + 1) + min) * decimalMultiplicator) / decimalMultiplicator;
  };

  const updateModuleValues = () => {
    console.log("updateModuleValues called");

    setInterval(() => {
      dispatch(updateModuleValue({
        id : "13", value : random(0, 9999, 1000)
      }));
      dispatch(updateModuleValue({
        id : "15", value : random(-70, 80, 100)
      }));
      dispatch(updateModuleValue({
        id : "14", value : random(-60000, 60000, 1000)
      }));
      dispatch(updateModuleValue({
        id : "27", value : random(5, 30, 1000)
      }));

    }, 2000);
  };

  return {
    hydrateFakeData,
    updateModuleValues
  }
}

// #### Advertisements returned by requestLEScan
// 0x020106 0B09 545257532D3132333435 0FFF FF02 C5 19 12345678 01 57682f6d32
// explanation:
// 0x020106 flags
// 0B09 545257532D3132333435: name - "TRWS-12345"
// 0FFF FF02: Manufacturer Data - Silicon Laboratories (we filter by this)
//    C5 : status - error in this case
//    0A : voltage - 10 = 1.0
//    12345678 : 4B floating point value
//    01 : number of decimal points - 1
//    57682f6d32 : unit -  "Wh/m2"
// ----------
// real TRWS-#1 device:
// 0x020106
// 0B09 54-52-57-53-23-2D-31-20-20-20 - name TRWS-#1
// 0FFF FF02 - mfg data, silabs
// 70 08 00-00-00-00 03 6D-6D-20-20-20 - manufacturer data
// -----------
// real RQA device:
// 00 21 00-50-B8-3D 01 25-20-20-20-20 - manufacturer data
// 00 00 ff ff ff ff 01 25 20 20 20 20 - manufacturer data
//
export const fakeScannedPeripherals : Array<ScanResult> = [
  {
    "device"  : {
      // Android:
      "deviceId"        : "8C:F6:81:99:85:55",
      // ios:
      // "deviceId"        :"47A5E86F-E1D6-253A-347E-7A95E5BA31C2",
      "name"            : "fake RQA#34133 ",
    },
    "localName"         : "fake RQA#34133 ",
    "rssi"              : -42,
    "txPower"           : 127,
    "manufacturerData"  : {
      // @note: 767 = 02FF = Silicon Laboratories
      // value should be identical on every platform
      "767"             : new DataView(hexStringToBytes("00210050B83D012520202020"))
      // "767"             : new DataView(hexStringToBytes("002100C0F53C012520202020"))
    },
    "serviceData"       : {},
    "uuids"             : [],
    "rawAdvertisement"  : new DataView(hexStringToBytes(""))  // DataView 62 - Android only
  },
  {
    "device"            : {
      "deviceId"        : "14:B4:57:89:43:CB",
      "name"            : "fake TRWS#-1   "
    },
    "localName"         : "fake TRWS#-1   ",
    "rssi"              : -61,
    "txPower"           : 127,
    "manufacturerData"  : {
      "767"             : new DataView(hexStringToBytes("700800000000036D6D202020"))
    },
    "serviceData"       : {},
    "uuids"             : [],
    // 0201060B09545257532D31323334350FFFFF02C50A123456780157682f6d32 - but should be 62 in full I guess
    "rawAdvertisement"  : new DataView(hexStringToBytes(""))  // DataView 62 - Android only
  },
  {
    "device"            : {
      "deviceId"        : "33:33:33:33:33:33",
      "name"            : "Other Device"
    },
    "localName"         : "Other Device",
    "rssi"              : -87,
    "txPower"           : 127,
    "manufacturerData"  : {
      // probably incorrect, just took 12 last bytes from advertisement
      "0"               : new DataView(hexStringToBytes("03030378FE06FF650001C904"))
    },
    "serviceData"       : {},
    "uuids"             : [],
    // 0201061916F7FD01BD36C197586D592B24D6F04E2E64DBB50000000003030378FE06FF650001C904
    "rawAdvertisement"  : new DataView(hexStringToBytes(""))  // DataView 62 - Android only
  }
];

export const fakePeripherals = [
  {
    name          : "fake TRWS-12345",
    id            : "14:B4:57:89:43:CB",
    rssi          : -44,
    mtu           : 0,

    // final parsed manufacturerData - to be displayed to user
    // manufacturerData  : {
    //   value       : 30541989.6,
    //   decimals    : 2,
    //   unit        : "Wh/m2",
    //   status      : "C5",
    //   voltage     : 2.5
    // },
    manufacturerData : undefined,

    // after connection - data gained from BLE connect procedure.
    services      : [
      {
        "uuid":"00001801-0000-1000-8000-00805f9b34fb",
        "characteristics":[
          {"uuid":"00002a05-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":false,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":true,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"00002b2a-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"00002b29-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":true,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]}
        ]
      },
      {
        "uuid":"00001800-0000-1000-8000-00805f9b34fb",
        "characteristics":[
          {"uuid":"00002a00-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"00002a01-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]}
        ]
      },
      {
        "uuid":"0000180a-0000-1000-8000-00805f9b34fb",
        "characteristics":[
          {"uuid":"00002a29-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"00002a24-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"00002a25-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"00002a27-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"00002a26-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"00002a23-0000-1000-8000-00805f9b34fb","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]}
        ]
      },
      // Modules
      {
        "uuid":"1c11c297-d9c3-4f10-9758-3ace53318914",
        "characteristics":[
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fe0","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"},{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fe1","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"},{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fe2","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"},{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"b97785ba-56d0-4b6c-9ae7-ed31e7e971f3","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"},{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"a712568b-a1c4-47a6-b220-e095d4e18c5d","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"},{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fe3","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"},{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fe4","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"},{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]}
        ]
      },
      // Settings
      {
        "uuid":"00a0818c-435f-4496-837b-c060ba350c67",
        "characteristics":[
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fea","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fe7","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":false,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fe5","properties":{"broadcast":false,"read":true,"writeWithoutResponse":false,"write":true,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"46b9d8fe-1165-4225-9450-8a33058f6fec","properties":{"broadcast":false,"read":false,"writeWithoutResponse":false,"write":true,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]},
          {"uuid":"67360efa-324a-4762-9fbe-862630c526d8","properties":{"broadcast":false,"read":false,"writeWithoutResponse":false,"write":true,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002901-0000-1000-8000-00805f9b34fb"},{"uuid":"00002904-0000-1000-8000-00805f9b34fb"},{"uuid":"00002906-0000-1000-8000-00805f9b34fb"},{"uuid":"00002909-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f0-0000-1000-8000-00805f9b34fb"},{"uuid":"000029f1-0000-1000-8000-00805f9b34fb"}]}
        ]
      },
      // OTA
      {
        "uuid":"1d14d6ee-fd63-4fa1-bfa4-8f47b42119f0",
        "characteristics":[
          {"uuid":"f7bf3564-fb6d-4e53-88a4-5e37e0326063","properties":{"broadcast":false,"read":false,"writeWithoutResponse":false,"write":true,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"984227f3-34fc-4045-a5d0-2c581f81a153","properties":{"broadcast":false,"read":false,"writeWithoutResponse":true,"write":true,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]}
        ]
      },
      // UART
      {
        "uuid":"6e400001-b5a3-f393-e0a9-e50e24dcca9e",
        "characteristics":[
          {"uuid":"6e400002-b5a3-f393-e0a9-e50e24dcca9e","properties":{"broadcast":false,"read":false,"writeWithoutResponse":false,"write":true,"notify":false,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[]},
          {"uuid":"6e400003-b5a3-f393-e0a9-e50e24dcca9e","properties":{"broadcast":false,"read":false,"writeWithoutResponse":false,"write":false,"notify":true,"indicate":false,"authenticatedSignedWrites":false,"extendedProperties":false},"descriptors":[{"uuid":"00002902-0000-1000-8000-00805f9b34fb"}]}
        ]
      }
    ],

    connected     : true,

    // after we process the connected device, these fields are present:
    // name, id, rssi, characteristics, services - and then: modules, settings, otaSupport, uartSupport
    modules : [
      {
        id              : "13",
        serviceId       : "1c11c297-d9c3-4f10-9758-3ace53318914",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": false, "write": false,
          "notify": true, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Precipitation",
        icon            : "water-outline",
        unit            : "mm",   // or enum?
        min             : 0,
        max             : 9999,
        decimals        : 3,
        exponent        : 0,
        format          : 20,
        graph           : GraphTypes.histogram,
        values          : [
          { timestamp : 1, value : 10.01 },
          { timestamp : 2, value : 15.253 },
          { timestamp : 3, value : 17.112 },
          { timestamp : 4, value : 120 },
          { timestamp : 5, value : 185.574 },
          { timestamp : 6, value : 2000.124 },
          { timestamp : 7, value : 2300.56 },
          { timestamp : 8, value : 1500.1 },
          { timestamp : 9, value : 1000.157 },
          { timestamp : 10, value : 450.454 }
        ],
        value           : 450.454,
      },
      {
        id              : "15",
        serviceId       : "1c11c297-d9c3-4f10-9758-3ace53318914",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": false, "write": false,
          "notify": true, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Temperature avg",
        icon            : "thermometer-outline",
        unit            : "°C",   // or enum?
        min             : -70,
        max             : 80,
        decimals        : 2,
        exponent        : 0,
        format          : 20,
        graph           : GraphTypes.oneAxis,
        values          : [
          { timestamp : 1, value : 50 },
          { timestamp : 2, value : 42.01 },
          { timestamp : 3, value : 17.82 },
          { timestamp : 4, value : 0.00 },
          { timestamp : 5, value : -5.98 },
          { timestamp : 6, value : -38.42 },
          { timestamp : 7, value : -25.65 },
          { timestamp : 8, value : 7.12 },
          { timestamp : 9, value : 25.46 },
          { timestamp : 10, value : 33.98 }
        ],
        value           : 33.98,
      },
      {
        id              : "14",
        serviceId       : "1c11c297-d9c3-4f10-9758-3ace53318914",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": false, "write": false,
          "notify": true, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Weight avg",
        icon            : "lock-closed-outline",
        unit            : "g",   // or enum?
        min             : -60000,
        max             : 60000,
        decimals        : 3,
        exponent        : 0,
        format          : 20,
        graph           : GraphTypes.oneAxis,
        values          : [
          { timestamp : 1, value : 1000.124 },
          { timestamp : 2, value : 2000.542 },
          { timestamp : 3, value : 30000.6 },
          { timestamp : 4, value : -2500.99 },
          { timestamp : 5, value : -7000.524 },
          { timestamp : 6, value : 18000.654 },
          { timestamp : 7, value : 59000.622 },
          { timestamp : 8, value : -59000.456 },
          { timestamp : 9, value : 0 },
          { timestamp : 10, value : -35800.524 }
        ],
        value           : -35800.524,
      },
      {
        id              : "27",
        serviceId       : "1c11c297-d9c3-4f10-9758-3ace53318914",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": false, "write": false,
          "notify": true, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Resistance avg",
        icon            : "flash-outline",
        unit            : "Ω",   // or enum?
        min             : null,
        max             : null,
        decimals        : 3,
        exponent        : 0,
        format          : 20,
        graph           : GraphTypes.oneAxis,
        values          : [
          { timestamp : 1, value : 50 },
          { timestamp : 2, value : 42.01 },
          { timestamp : 3, value : 17.82 },
          { timestamp : 4, value : 0.00 },
          { timestamp : 5, value : -5.98 },
          { timestamp : 6, value : -38.42 },
          { timestamp : 7, value : -25.65 },
          { timestamp : 8, value : 7.12 },
          { timestamp : 9, value : 25.46 },
          { timestamp : 10, value : 33.98 }
        ],
        value           : 33.98,
      }
    ],

    settings : [
      {
        id              : "100", // ? not in the specification paper
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Serial number (utf8 string)",
        icon            : "settings-outline",
        unit            : "",
        type            : SettingTypes.string,
        min             : 0,  // not needed
        max             : 0,  // not needed
        decimals        : 0,  // not needed
        exponent        : 0,
        format          : 25,
        writable        : true,
        value           : "A3JDS8-15SDSDF-AVCB",
        info            : "info descriptor text"
      },
      {
        id              : "102", // ? not in the specification paper
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Boolean setting",
        icon            : "contrast-outline",
        unit            : "",
        type            : SettingTypes.boolean,
        min             : 0,  // not needed
        max             : 0,  // not needed
        decimals        : 0,  // not needed
        exponent        : 0,  // not needed
        format          : 1,
        writable        : true,
        value           : 1,
        info            : "info descriptor text"
      },
      {
        id              : "1",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Uint8 setting",
        icon            : "settings",
        unit            : "cm2",
        type            : SettingTypes.number,
        min             : 10,
        max             : 2500,
        decimals        : 0,
        exponent        : 1,
        format          : 4,  // uint8
        writable        : true,
        value           : 101,
        info            : "info descriptor text"
      },
      {
        id              : "900",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Uint16 setting",
        icon            : "settings",
        unit            : "W/hm2",
        type            : SettingTypes.number,
        min             : 1000,
        max             : 60000,
        decimals        : 0,
        exponent        : 2,
        format          : 6,  // uint16
        writable        : true,
        value           : 16001,
        info            : "info descriptor text"
      },
      {
        id              : "901",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Uint32 setting",
        icon            : "hammer-outline",
        unit            : "W/hm2",
        type            : SettingTypes.number,
        min             : 154000,
        max             : 4123456789,
        decimals        : 2,
        exponent        : 2,
        format          : 8,  // uint32
        writable        : true,
        value           : 389854400,
        info            : "info descriptor text"
      },

      {
        id              : "902",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Int8 setting",
        icon            : "cog-outline",
        unit            : "cm2",
        type            : SettingTypes.number,
        min             : -100,
        max             : 120,
        decimals        : 0,
        exponent        : -1,
        format          : 12,  // int8
        writable        : true,
        value           : -50.3,
        info            : "info descriptor text"
      },
      {
        id              : "903",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Int16 setting",
        icon            : "aperture-outline",
        unit            : "W/hm2",
        type            : SettingTypes.number,
        min             : -30000,
        max             : 30000,
        decimals        : 0,
        exponent        : -2,
        format          : 14,  // int16
        writable        : true,
        value           : -24000.50,
        info            : "info descriptor text"
      },
      {
        id              : "904",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Int32 setting",
        icon            : "analytics-outline",
        unit            : "W/hm2",
        type            : SettingTypes.number,
        min             : -2123456789,
        max             : 2123456789,
        decimals        : 0,
        exponent        : 2,
        format          : 16,  // Int32
        writable        : true,
        value           : -1823953807,
        info            : "info descriptor text"
      },
      {
        id              : "905",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Float32 setting",
        icon            : "analytics-outline",
        unit            : "W/hm2",
        type            : SettingTypes.number,
        min             : -800.32,
        max             : 19518.88,
        decimals        : 2,
        exponent        : 0,
        format          : 20,  // Float32
        writable        : true,
        value           : 5983.23,
        info            : "info descriptor text"
      },
      {
        id              : "906",
        serviceId       : "00a0818c-435f-4496-837b-c060ba350c67",
        properties      : {"broadcast": false, "read": true, "writeWithoutResponse": true, "write": true,
          "notify": false, "indicate": false, "authenticatedSignedWrites": false, "extendedProperties": false},
        descriptors     : [], // [{uuid}, {...}]
        descriptorsRead : true,
        name            : "Float64 setting",
        icon            : "analytics-outline",
        unit            : "W/hm2",
        type            : SettingTypes.number,
        min             : -800.32,
        max             : 19518.88,
        decimals        : 2,
        exponent        : 0,
        format          : 21,  // Float64
        writable        : true,
        value           : 18239.49,
        info            : "info descriptor text"
      },
    ],

    otaSupport          : true,
    uartSupport         : true,

    uartLog             : [
      { timestamp : 1599486848126, type : "central", msg : "0Xstatus!" },
      { timestamp : 1599486848526, type : "peripheral", msg : "response1" },
      { timestamp : 1599487043921, type : "central", msg : "0Xident!" },
      { timestamp : 1599487045383, type : "peripheral", msg : "response2" },
      { timestamp : 1599487080011, type : "central", msg : "0Xinfo!" },
      { timestamp : 1599487080811, type : "peripheral", msg : "response3response3response3response3response3s" }
    ]
  }
];

const fakePeripheralSliceState = {
  peripherals   : fakePeripherals,

  connectedId   : "59:74:08:88:1E:0E"
};

export default fakePeripheralSliceState;
