import React, {useCallback, useEffect, useRef} from 'react';
import {
  IonCard,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import {useHistory} from "react-router-dom";
import {toggleSelectedModule, getSelectedModulesIds} from "../store/peripheralsSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import Hammer from "hammerjs"

type ModuleProps = {
  name: string,
  id?: string,
  value?: any,
  decimals? : number,
  unit?: any,
  icon: string,
  href?: string,
  highlighted: boolean,
};

const ModuleCard: React.FC<ModuleProps> = (
  {
    name,
    id,
    value = null,
    unit,
    decimals,
    icon,
    href,
    highlighted}
  ) => {

  const dispatch = useAppDispatch();
  let history = useHistory();
  const selectedModules = useAppSelector(getSelectedModulesIds);

  const cardElement = useRef<HTMLIonCardElement>(null);

  /**
   * Simple clicks / taps are processed here
   */
  const clickCallback = useCallback((event:any) => {

    // Href is not defined, this is just an ordinary link (e.g. Settings)
    if (href !== undefined) {
      history.push(href);
      return;
    }

    if (selectedModules.length > 0) {
      // There are already some modules selected, treat click as "toggle"
      dispatch(toggleSelectedModule(id));
    } else {
      // No modules selected, click is a click
      dispatch(toggleSelectedModule(id));
      history.push("/detail");
    }

  }, [selectedModules]);

  /**
   * Presses / long presses are processed here.
   * Nothing special, just toggle.
   */
  const longPressCallback = useCallback(() => {
    dispatch(toggleSelectedModule(id));
  }, [selectedModules]);

  /**
   * Handle registering and unregistering of HammerJS functionality
   * on a specific card (using cardElement ref)
   */
  useEffect(() => {
    let cardWithHammer: HammerManager;

    if (cardElement.current !== null) {
      cardWithHammer = new Hammer(cardElement.current);
      cardWithHammer.on("tap", clickCallback);
      cardWithHammer.on("press", longPressCallback);
    }

    return () => {
      if (cardElement !== null && cardWithHammer !== undefined) {
        cardWithHammer.stop(true);
        cardWithHammer.destroy();
      }
    }
  }, [selectedModules]);

  // This was just too long to keep it inline.
  // And the 'active' class decision is more readable here.
  let cardClasses = 'ion-text-center ion-padding ion-justify-content-center ion-align-items-center ' +
    'mps-module-card mps-cursor-pointer';
  if (highlighted) {
    cardClasses += ' active';
  }

  return (
      <IonCard className={cardClasses}
               ref={cardElement}
      >
        <div className="mps-module-card-icon">
          <IonIcon src={"assets/svg/" + icon + ".svg"} size="large" aria-hidden={true}></IonIcon>
        </div>

        <div className="mps-module-card-unit">{unit}</div>

        {value !== null ? (
          <>
            <IonCardTitle className="mps-module-card-title">
              {value.toFixed(decimals)}
            </IonCardTitle>
            <div className="mps-module-card-footer mps-bg-highlight">
              {name}
            </div>
          </>
        ) : (
          <IonCardTitle>
            {name}
          </IonCardTitle>
        )}

      </IonCard>
  );
};

export default ModuleCard;
