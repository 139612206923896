import {BleCharacteristicProperties} from "@capacitor-community/bluetooth-le";
import {ModuleBase, Peripheral} from "../types";

export const newModuleBase = () : ModuleBase => ({
  id              : "",
  serviceId       : "",
  properties      : {} as BleCharacteristicProperties,
  descriptors     : [],
  descriptorsRead : false,

  name            : "(loading)",
  icon            : "help-outline",
  unit            : null,
  min             : null,
  max             : null,
  decimals        : 0,
  exponent        : 0,
  format          : 0,
});


export const newPeripheral = ({
  id = "",
  name = "",
  services = undefined,
  rssi,
  manufacturerData = undefined
} : Peripheral) : Peripheral => ({
  id                      : id,
  name                    : name,
  rssi                    : rssi,
  mtu                     : 23,

  manufacturerData        : manufacturerData,

  services                : services,

  modules                 : [],
  settings                : [],
  otaSupport              : false,
  uartSupport             : false,
  uartNotifying           : false,
});