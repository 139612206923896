import {
  Module,
  ModuleBase,
  MpsManufacturerData,
  ParsedDescriptors,
  Peripheral,
  Setting,
  SettingTypes,
} from "../types.d";
import {
  BleCharacteristic,
  BleService,
  ScanResult,
  dataViewToHexString
} from "@capacitor-community/bluetooth-le";
import {Dispatch} from "@reduxjs/toolkit";
import {newModuleBase, newPeripheral} from "./factories";
import isArray from "lodash/isArray";
import round from "lodash/round";
import {
  bytesToUtf8String,
  bytesToBoolArray,
  bytesToFloat32Array, bytesToFloat64Array,
  bytesToInt8Array, bytesToInt16Array, bytesToInt32Array,
  bytesToUint8Array, bytesToUint16Array, bytesToUint32Array,
  hexStringToUtf8String,
  hexStringToBytes,
  boolToBytes,
  utf8StringToBytes,
  uint8ToBytes, uint16ToBytes, uint32ToBytes,
  int8ToBytes, int16ToBytes, int32ToBytes,
  stringToIntWithExponent, stringToFloat,
  float64ToBytes, float32ToBytes,
} from "./conversions";
import {CharacteristicFormats} from "./bleDefinition";
import {
  advValueLittleEndian,
  charaValueLittleEndian,
  descDecimalsFull,
  descFormatFull,
  descIconFull,
  descNameFull,
  descRangeFull,
  descUnitFull,
  modulesServiceUuid,
  settingsServiceUuid,
  otaServiceUuid,
  otaControlCharaUuid,
  otaDataCharaUuid,
  uartServiceUuid,
  uartRxWriteCharaUuid,
  uartTxNotifyCharaUuid
} from "../config";
import {logToEverywhere} from "../store/logSlice";
import {log} from "./utils";

const logTag = "bleParsers";


// Peripherals are filtered by two criteria:
// 1. their manufacturer must be Silicon Laboratories, 767 = 0x02FF (0xFF02 in raw advertisement)
// 2. the respective lengths of advertised fields (status, voltage, decimals, value) must be exactly as
// specified
export const parseManufacturerData = (scanResult : ScanResult) : MpsManufacturerData => {

  const peripheralName = scanResult.localName;
  const peripheralId = scanResult.device.deviceId;

  // @note: 767 = 02FF = Silicon Laboratories
  if (!scanResult.manufacturerData || (!(scanResult.manufacturerData?.["767"] instanceof DataView) ?? true)) {
    throw new Error(`${peripheralName} (${peripheralId}) advertisement: missing manufacturer data`);
  }

  // convert the DataView to hex string we can slice and analyze
  const dataHex = dataViewToHexString(scanResult.manufacturerData["767"]).split(" ").join("");

  // status (e.g. error state)
  const status = dataHex.slice(0, 2);
  if (status.length !== 2) {
    throw new Error(`${peripheralName} (${peripheralId}) advertisement: unsupported format of Status: ${status}. Data: ${dataHex}`);
  }

  // current voltage of the peripheral
  const voltageHex = dataHex.slice(2, 4);
  let voltage = parseInt(voltageHex, 16);
  if (voltageHex.length !== 2 || isNaN(voltage)) {
    throw new Error(`${peripheralName} (${peripheralId}) advertisement: unsupported format of Voltage: ${voltageHex}. Data: ${dataHex}`);
  }
  // voltage has fixed one decimal place as per MPS specification
  voltage = voltage / 10;

  // decimals to which the advertised value should be rounded
  const decimalsHex = dataHex.slice(12, 14);
  const decimals = parseInt(decimalsHex, 16);
  if (decimalsHex.length !== 2 || isNaN(decimals)) {
    throw new Error(`${peripheralName} (${peripheralId}) advertisement: unsupported format of Decimals: ${decimalsHex}. Data: ${dataHex}`);
  }

  // the actual 32bit float value. Watch out for endian!
  const valueHex = dataHex.slice(4, 12);
  if (valueHex.length !== 8) {
    throw new Error(`${peripheralName} (${peripheralId}) advertisement: unsupported format of Value: ${valueHex}. Data: ${dataHex}`);
  }
  const valueBeforeRound = bytesToFloat32Array(hexStringToBytes(valueHex), advValueLittleEndian)[0];
  const value = round(valueBeforeRound, decimals);

  // unit of the value is in string form, max 5 characters according to spec
  // @note tailing zeroes don't matter, the conversion cuts that off
  const unit = hexStringToUtf8String(dataHex.slice(14));
  if (unit.length === 0 || unit.length > 5) {
    throw new Error(`${peripheralName} (${peripheralId}) advertisement: incorrect length of Unit. Data: ${dataHex}`);
  }

  return {value, decimals, unit, status, voltage};
}

const isThisNumericFormat = (format : number) : boolean => {
  switch (format) {
    case CharacteristicFormats.uint8:
    case CharacteristicFormats.uint16:
    case CharacteristicFormats.uint32:
    case CharacteristicFormats.uint64:
    case CharacteristicFormats.int8:
    case CharacteristicFormats.int16:
    case CharacteristicFormats.int32:
    case CharacteristicFormats.int64:
    case CharacteristicFormats.float32:
    case CharacteristicFormats.float64:
      return true;
    case CharacteristicFormats.boolean:
    case CharacteristicFormats.utf8string:
    default:
      return false;
  }
};

// Returns a parser function needed for the given format. This only deals with numeric types.
// format numbers are defined here: https://www.bluetooth.com/specifications/assigned-numbers/format-types/
const formatToNumericParser = (format : number) => {
  switch (format) {
    case CharacteristicFormats.uint8: return bytesToUint8Array;
    case CharacteristicFormats.uint16: return bytesToUint16Array;
    case CharacteristicFormats.uint32: return bytesToUint32Array;
    // case CharacteristicFormats.uint64: return bytesToUint64Array; // @note: problem with bigint format
    case CharacteristicFormats.int8: return bytesToInt8Array;
    case CharacteristicFormats.int16: return bytesToInt16Array;
    case CharacteristicFormats.int32: return bytesToInt32Array;
    // case CharacteristicFormats.int64: return bytesToInt64Array;   // @note: problem with bigint format
    case CharacteristicFormats.float32: return bytesToFloat32Array;
    case CharacteristicFormats.float64: return bytesToFloat64Array;
    default: return undefined;
  }
};

// Returns a parser function needed for the given format. This deals with boolean and strings
const formatToNonNumericParser = (format : number) => {
  switch (format) {
    case CharacteristicFormats.boolean: return bytesToBoolArray;
    case CharacteristicFormats.utf8string: return bytesToUtf8String;
    default: return undefined;
  }
};

// format numbers are defined here: https://www.bluetooth.com/specifications/assigned-numbers/format-types/
// this is a generic format -> parser conversion
const formatToValueParser = (format : number) => {
  switch (format) {
    case CharacteristicFormats.boolean: return bytesToBoolArray;
    case CharacteristicFormats.uint8: return bytesToUint8Array;
    case CharacteristicFormats.uint16: return bytesToUint16Array;
    case CharacteristicFormats.uint32: return bytesToUint32Array;
    // case CharacteristicFormats.uint64: return bytesToUint64Array; // @note: problem with bigint format
    case CharacteristicFormats.int8: return bytesToInt8Array;
    case CharacteristicFormats.int16: return bytesToInt16Array;
    case CharacteristicFormats.int32: return bytesToInt32Array;
    // case CharacteristicFormats.int64: return bytesToInt64Array;   // @note: problem with bigint format
    case CharacteristicFormats.float32: return bytesToFloat32Array;
    case CharacteristicFormats.float64: return bytesToFloat64Array;
    case CharacteristicFormats.utf8string: return bytesToUtf8String;
    default: return undefined;
  }
};

const formatToNumericByteParser = (format : number) => {
  switch (format) {
    case CharacteristicFormats.uint8: return uint8ToBytes;
    case CharacteristicFormats.uint16: return uint16ToBytes;
    case CharacteristicFormats.uint32: return uint32ToBytes;
    case CharacteristicFormats.int8: return int8ToBytes;
    case CharacteristicFormats.int16: return int16ToBytes;
    case CharacteristicFormats.int32: return int32ToBytes;
    case CharacteristicFormats.float32: return float32ToBytes;
    case CharacteristicFormats.float64: return float64ToBytes;
    default: return undefined;
  }
};

export const formatToSettingType = (format : number) => {
  switch (format) {
    case CharacteristicFormats.boolean:
      return SettingTypes.boolean;
    case CharacteristicFormats.utf8string:
      return SettingTypes.string;
    case CharacteristicFormats.uint8:
    case CharacteristicFormats.uint16:
    case CharacteristicFormats.uint32:
    case CharacteristicFormats.int8:
    case CharacteristicFormats.int16:
    case CharacteristicFormats.int32:
    case CharacteristicFormats.float32:
    case CharacteristicFormats.float64:
    default:
      return SettingTypes.number;
  }
};

const isExponentUsedWithFormat = (format : number) : boolean => {
  switch (format) {
    case CharacteristicFormats.uint8:
    case 5:
    case CharacteristicFormats.uint16:
    case 7:
    case CharacteristicFormats.uint32:
    case 9:
    case CharacteristicFormats.uint64:
    case 11:
    case CharacteristicFormats.int8:
    case 13:
    case CharacteristicFormats.int16:
    case 15:
    case CharacteristicFormats.int32:
    case 17:
    case CharacteristicFormats.int64:
    case 19:
      return true;
    default:
      return false;
  }
};


// split the services to Modules, Settings and determine UART and OTA support
// services before processing:
// [{ uuid, characteristics : [{ uuid, properties, descriptors : [{ uuid }, {...}] }, {...}]}, {...}]
export const parseConnectedPeripheral = (
  peripheralId : string, peripheralName : string, services : Array<BleService>, actualMtu : number
) : Peripheral => {
  let connectedPeripheral : Peripheral = newPeripheral({
    id : peripheralId, name : peripheralName, services
  });

  // set the actual negotiated MTU, if it is known (so only for Android and iOS)
  connectedPeripheral.mtu = actualMtu;

  // parse the charas and descriptors to so-called Modules - main device values
  connectedPeripheral.modules = parseModules(connectedPeripheral);

  // parse the charas and descriptors to so-called Settings - configurable values
  connectedPeripheral.settings = parseSettings(connectedPeripheral);

  // determine OTA support
  connectedPeripheral.otaSupport = hasOtaSupport(connectedPeripheral);

  // determine UART support
  connectedPeripheral.uartSupport = hasUartSupport(connectedPeripheral);

  return connectedPeripheral;
};

// parse and format the Modules - main device values
const parseModules = (peripheral : Peripheral) : Array<Module> => {
  const modulesService = peripheral.services?.find(
    (service : BleService) => modulesServiceUuid === service.uuid.toLowerCase()
  );

  let modulesArray = new Array<Module>();

  if (modulesService) {
    log.log(logTag, "Modules: " + modulesService.characteristics.length);
    modulesArray = modulesService.characteristics.map((characteristic : BleCharacteristic) => ({
      ...newModuleBase(),
      id          : characteristic.uuid,
      serviceId   : modulesService.uuid,
      properties  : characteristic.properties,
      descriptors : characteristic.descriptors
    }));
  }

  return modulesArray;
};

// parse and format the Settings - configurable device values
const parseSettings = (peripheral : Peripheral) : Array<Setting> => {
  const settingsService = peripheral.services?.find(
    (service : BleService) => settingsServiceUuid === service.uuid.toLowerCase()
  );

  let settingsArray = new Array<Setting>();

  if (settingsService) {
    log.log(logTag, "Settings: " + settingsService.characteristics.length);
    settingsArray = settingsService.characteristics.map((characteristic : BleCharacteristic) => {
      const moduleBase = newModuleBase();
      return {
        ...moduleBase,
        id          : characteristic.uuid,
        serviceId   : settingsService.uuid,
        properties  : characteristic.properties,
        descriptors : characteristic.descriptors,

        // this is just for our convenience, so that we don't have to ask about writability
        writable    : characteristic?.properties?.write || characteristic?.properties?.writeWithoutResponse,
        // this is temporary! we'll change it as soon as we read & parse descriptors
        type        : SettingTypes.string,
        info        : ""
      };
    });
  }

  return settingsArray;
};

// check if the peripheral has the specific service and charas necessary for OTA updates
const hasOtaSupport = (peripheral : Peripheral) : boolean => {
  const otaService = peripheral.services?.find(
    (service : BleService) => otaServiceUuid === service.uuid.toLowerCase()
  );

  if (!otaService
    || !otaService.characteristics.find((chara : BleCharacteristic) => chara.uuid === otaControlCharaUuid)
    || !otaService.characteristics.find((chara : BleCharacteristic) => chara.uuid === otaDataCharaUuid)
  ) {
    log.log(logTag, `Peripheral ${peripheral.id} does NOT support OTA`);
    return false;
  }

  log.log(logTag, `Peripheral ${peripheral.id} supports OTA`);
  return true;
};

// check if the peripheral has the specific service and charas necessary for UART
const hasUartSupport = (peripheral : Peripheral) : boolean => {
  const uartService = peripheral.services?.find(
    (service : BleService) => uartServiceUuid === service.uuid.toLowerCase()
  );

  if (!uartService
    || !uartService.characteristics.find((chara : BleCharacteristic) => chara.uuid === uartRxWriteCharaUuid)
    || !uartService.characteristics.find((chara : BleCharacteristic) => chara.uuid === uartTxNotifyCharaUuid)
  ) {
    log.log(logTag, `Peripheral ${peripheral.id} does NOT support UART`);
    return false;
  }

  log.log(logTag, `Peripheral ${peripheral.id} supports UART`);
  return true;
};

export const formatCharaValue = (value : number, exponent : number, decimals : number) : number => {
  return round(value * Math.pow(10, exponent), decimals);
};

// format the Modules or Settings - main device values
export const parseDescriptors = (descriptors : any, module : ModuleBase, dispatch : Dispatch<any>)
  : ParsedDescriptors => {

  // descriptor containing the name of the characteristic
  const name = descriptors?.[descNameFull]?.value
    ? bytesToUtf8String(descriptors[descNameFull].value.buffer)
    : module.id;

  // our custom descriptor with unit of the value in string format
  const unit = descriptors?.[descUnitFull]?.value
    ? bytesToUtf8String(descriptors[descUnitFull].value.buffer)
    : null;

  // our custom descriptor with name of specific ionicon
  const icon = descriptors?.[descIconFull]?.value
    ? bytesToUtf8String(descriptors[descIconFull].value.buffer)
    : "analytics-outline";

  // descriptor containing number of decimals
  const decimals = descriptors?.[descDecimalsFull]?.value
    ? descriptors[descDecimalsFull].value.getUint8(0)
    : 0;

  // the Characteristic Presentation Format descriptor contains format (uint8, float32 etc) for Chara Value
  // and also for Valid Range - in its first byte. Second byte is Exponent. Remaining bytes are unused
  // [0, 0] means format = unsupported, exponent = 0
  const formatAll = descriptors?.[descFormatFull]?.value
    ? new Int8Array(descriptors[descFormatFull].value.buffer)
    : [0, 0];
  const format = formatAll[0];
  const exponent = isExponentUsedWithFormat(format) ? formatAll[1] : 0;

  // now let's parse the Valid Range descriptor with the help of Format, Exponent and Decimals
  // @note: maybe parses could be split
  const parser = formatToValueParser(format);
  let min = null;
  let max = null

  // no parser means the characteristic has a format we don't support
  if (!parser) {
    const msg = `${name}: unsupported format of characteristic: ${module.id}, service: ${module.serviceId}, format: ${format}`;
    dispatch(logToEverywhere(logTag, "Unsupported format of characteristic", "error", false, msg));

  // if the format is boolean, min and max make no sense. TS doesn't understand this guard
  } else if (!isThisNumericFormat(format)) {
    log.log(logTag, `${name}: parser does not return a number format, min and max make no sense`);

  } else {
    // Valid Range descriptor contains two values: min and max values for Chara Value. These have the same
    // format and exponent as the Chara Value itself - i.e. using the Chara Presentation Format descriptor
    // @note: typescript has problems with one of the parsers returning boolean, which will never happen
    if (descriptors?.[descRangeFull]?.value) {
      const range = parser(descriptors[descRangeFull].value.buffer, charaValueLittleEndian);
      // @ts-ignore
      if (isFinite(range[0])) min = formatCharaValue(range[0], exponent, decimals);
      // @ts-ignore
      if (isFinite(range[1])) max = formatCharaValue(range[1], exponent, decimals);

    } else {
      log.log(logTag, `${name}: Unable to parse the Range!`);
    }
  }

  return {
    name,
    unit,
    icon,
    decimals,
    exponent,
    format,
    min,
    max,
  };
};

export const parseInfoDescriptor = (descValue : DataView) : string => {
  return bytesToUtf8String(descValue.buffer);
}

// the main value parsing logic - format, exponent, decimals
// moduleBase can be of type Module or Setting
export const parseValues = (moduleBase : ModuleBase, valueBuffer : ArrayBuffer) => {

  // non-numeric formats: boolean (actually 0 | 1), utf8string, utf16string
  if (!isThisNumericFormat(moduleBase.format)) {
    const parser = formatToNonNumericParser(moduleBase.format);
    if (!parser) {
      log.log(logTag, `parseValues (${moduleBase.name}): no non-numeric parser found for ${moduleBase.name}!`);
      return null;
    }

    const parsedValues = parser(valueBuffer);
    if (!isArray(parsedValues)) {
      return [parsedValues];
    }
    return parsedValues;

  // numeric formats - dealing with exponent and decimals
  } else {
    const parser = formatToNumericParser(moduleBase.format);
    if (!parser) {
      log.log(logTag, `parseValues (${moduleBase.name}): no numeric parser found for ${moduleBase.name}!`);
      return null;
    }

    // no need to parse empty arrays, also that's not an error - e.g. right after OTA the Modules have no values
    if (valueBuffer.byteLength === 0) {
      return new Array<number>();
    }

    const valueRawArray = parser(valueBuffer, charaValueLittleEndian);
    let resultArray = new Array<number>();
    valueRawArray.forEach((valueRaw : number) => {
      if (isFinite(valueRaw)) {
        resultArray.push(formatCharaValue(valueRaw, moduleBase.exponent, moduleBase.decimals));
      } else {
        log.log(logTag, `parseValues (${moduleBase.name}): valueRaw is not finite: ${valueRaw}`);
      }
    });

    // if the result array has 0 length but the input array isn't, something wasn't parsed right
    return resultArray.length > 0 ? resultArray : null;
  }
};

// currently, it's just string, so this is kinda useless. but for consistency with Modules and Settings
export const parseUartNotify = (valueBuffer : ArrayBuffer) : string => {
  return bytesToUtf8String(valueBuffer);
}


export const convertValueToBytes = (moduleBase : ModuleBase, value: string) : ArrayBuffer | null => {
  if (moduleBase.format === CharacteristicFormats.boolean) {
    return boolToBytes(value);
  }

  if (moduleBase.format === CharacteristicFormats.utf8string) {
    return utf8StringToBytes(value);
  }

  // alright, so the format is either numeric or unsupported
  const numericParser = formatToNumericByteParser(moduleBase.format);
  if (!numericParser) {
    return null;
  }

  // so the format is supported, but can we parse the value from string to number?
  // @note that float32 and float64 types don't use exponent, but it should be 0 in that case
  let valueAsNumber : number | null = null;
  if (moduleBase.format === CharacteristicFormats.float32 || moduleBase.format === CharacteristicFormats.float64) {
    valueAsNumber = stringToFloat(value);
  } else {
    valueAsNumber = stringToIntWithExponent(value, moduleBase.exponent);
  }
  if (valueAsNumber === null) {
    return null;
  }

  log.log(logTag, "Converting value to bytes, parsed number: " + valueAsNumber);
  return numericParser(valueAsNumber, charaValueLittleEndian);
};

export const isReadable = (moduleBase : ModuleBase) => moduleBase.properties && moduleBase.properties.read;

export const isWritable = (moduleBase : ModuleBase) =>
  moduleBase.properties && (moduleBase.properties.write || moduleBase.properties.writeWithoutResponse);

export const isNotifiable = (moduleBase : ModuleBase) =>
  moduleBase.properties && moduleBase.properties.notify;

export const canUseWrite = (moduleBase : ModuleBase) => moduleBase.properties && moduleBase.properties.write;

export const canUseWriteWithoutResponse = (moduleBase : ModuleBase) =>
  moduleBase.properties && moduleBase.properties.writeWithoutResponse;