import {
  IonContent,
  IonPage,
  IonToolbar, IonTitle, IonHeader,
  IonButton,
  IonButtons,
  IonGrid, IonRow, IonCol,
  IonItem, IonInput,
  useIonViewWillEnter,
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {useHistory} from "react-router-dom";

import {
  getWriteInProgress,
  getWriteFinished,
  getWriteProgress,
  getWriteTimeSeconds,
  setWriteInProgress,
  setWriteFinished,
  resetWriteProgress
} from "../store/writeProgressSlice";
import {getCurrentPeripheral, isThereConnectedPeripheral} from "../store/peripheralsSlice";
import {toast} from "../store/logSlice";
import {otaAllowedExtensions, otaMaxFileSize, otaDefaultMtu, otaMaxMtu} from "../config";
import {useHwBack} from "../hooks/useHwBack";
import {useBle} from "../hooks/useBle";
import {useValidations} from "../hooks/useValidations";
import {useLog} from "../hooks/useLog";
import {isNativeApp} from "../utils/platforms";

import './AppSettings.css';
import BackButton from "../components/BackButton";

const OtaUpload: React.FC = () => {
  const logTag = "OtaUpload";
  const {logInfo, logError} = useLog(logTag);

  let history = useHistory();
  const isConnected = useAppSelector(isThereConnectedPeripheral);

  // conditional redirect to Main if no device is connected. Also works in case of disconnect
  useEffect(() => {
    if (!isConnected) {
      history.push('/main');
    }
  }, [isConnected, history]);

  // handling the HW back button
  useHwBack(logTag, null, []);

  const dispatch = useAppDispatch();

  const peripheral = useAppSelector(getCurrentPeripheral);

  const writeInProgress = useAppSelector(getWriteInProgress);
  const writeFinished = useAppSelector(getWriteFinished);
  const writeProgress = useAppSelector(getWriteProgress);
  const writeTimeSeconds = useAppSelector(getWriteTimeSeconds);
  const {writeOta, finishOta, getActualMtu} = useBle();
  const {validationMessage, clearValidationMessage, validateNumber} = useValidations();

  const [fastMode, setFastMode] = useState<boolean>(false);
  const [otaFile, setOtaFile] = useState<File>();
  const [otaResultMessage, setOtaResultMessage] = useState<string>();
  const [mtu, setMtu] = useState<string>(otaDefaultMtu.toString());
  const [maxMtu, setMaxMtu] = useState<number>(otaMaxMtu);

  // run once on enter. Notice peripheral as dependency - for cases when user refreshes while being here
  useIonViewWillEnter(() => {
    // reset write progress - otherwise it might stay stuck from previous OTA
    dispatch(resetWriteProgress());

    // if this is Android or iOS, we can get the actual MTU size and use it
    if (isNativeApp()) {
      const getMtu = async () => {
        const actualMtu : number = await getActualMtu(peripheral.id);
        setMtu(actualMtu.toString());
        setMaxMtu(actualMtu);
      }

      getMtu().catch(error =>
        logError(`Error while getting actual MTU of the peripheral`, false, error)
      );
    }
  }, [peripheral]);

  const prepareAndWriteOta = async () => {
    setOtaResultMessage("");

    if (!validateMtu(mtu)) {
      return;
    }

    if (otaFile === undefined) {
      return;
    }

    const reader = new FileReader();

    reader.readAsArrayBuffer(otaFile);

    reader.onloadend = async () => {
      let contents = reader.result as ArrayBuffer;

      try {
        dispatch(setWriteInProgress(true));
        logInfo(`Writing OTA file ${otaFile.name}: ${contents.byteLength} bytes, preferred MTU: ${mtu}`);

        await writeOta(peripheral, contents, Number(mtu), fastMode);

        // @note: can't use writeTimeSeconds here as it's 0 apparently
        logInfo(`OTA finished successfully. File: ${otaFile.name}`);
        dispatch(toast("OTA file uploaded, press Finish"));
        dispatch(setWriteFinished(true));

      } catch (error) {
        dispatch(setWriteInProgress(false));
        dispatch(toast("Error while writing OTA."));
        logError(`Error while writing OTA`, false, error);

        if (error instanceof Error) {
          // @note: if we wanted to inform the user about PIN, we'd have to detect the error here
          setOtaResultMessage(error.message);
        } else {
          setOtaResultMessage("Unexpected error - write failed!");
        }
      }
    }
  }

  const prepareOtaFile = async (event: any) => {
    let fileToStore = event.target.files[0];

    let fileExtension = fileToStore.name.split('.').pop();

    if (otaAllowedExtensions.indexOf(fileExtension) === -1) {
      dispatch(toast("File extension not allowed!"));
      setOtaFile(undefined);
      return;
    }

    if (fileToStore.size > otaMaxFileSize) {
      dispatch(toast("File too large, max size 1024kB"));
      setOtaFile(undefined);
      return;
    }

    setOtaFile(fileToStore);
  }

  const validateMtu = (newMtu : string) : boolean => {
    clearValidationMessage();
    // 244+3 is a limit from SiLabs documentation. Using a number higher than that can cause the write to
    // freeze, at least on Web
    return validateNumber(newMtu, 4, maxMtu);
  }

  const finishOtaAndDisconnect = async () => {
    try {
      await finishOta(peripheral);
      dispatch(resetWriteProgress());

    } catch (error) {
      logError("Error while finishing OTA", true, error);
    }
  }


  return (
    <IonPage id="mps-ota">
      <IonHeader className="mps-layout">
        <IonToolbar>
          <IonButtons slot="start">
            {writeInProgress === false ?
              (
                <BackButton />
              ): ""
            }
          </IonButtons>
          <IonTitle>OTA Upload</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="mps-layout">
        <IonItem className="mps-ota-item" >
          <div className="mps-ota-file-wrapper">
            <label className="mps-ota-file">
              <input type="file"
                     onChange={(e) => prepareOtaFile(e)}
                     className="mps-ota-file-input"
                     aria-label="File browser" />
              <span className="mps-ota-file-custom"></span>
            </label>
            {otaFile !== undefined ? (
              <div>
                File:
                <span className="mps-ota-file-selected"> {otaFile.name}</span>
                <br /><br />
                Size:
                <span className="mps-ota-file-selected"> {Math.round(otaFile.size / 1024)}kB</span>
                <br />
              </div>
            ) : (
              <span className="mps-ota-file-empty">No file selected</span>
            )}
          </div>
        </IonItem>

        <IonItem className="mps-ota-item">
          <IonInput label="MTU size in bytes:"
                    labelPlacement="stacked"
                    clearInput={false}
                    type="tel"
                    inputMode="numeric"
                    min="0"
                    max="247"
                    value={mtu}
                    onIonInput={(e) => {
                      const newMtu = `${e.detail.value}` || otaDefaultMtu.toString();
                      setMtu(newMtu);
                      validateMtu(newMtu);
                    }}
                    debounce={300}
                    helperText={
                      `MTU is the size of one packet. Higher MTU means faster OTA upload. ` +
                      `23 bytes is a safe default MTU size. `+
                      (isNativeApp()
                        ? " Actual maximum MTU negotiated between your platform and the peripheral was preselected."
                        : " Actual negotiated MTU for Web is currently not known.")
                    }
          ></IonInput>
        </IonItem>

        <div className="mps-error-text">
          {validationMessage}
        </div>

       {/*(platform === "android"  || platform === "web") ?
        (<IonList lines="none">
          <IonRadioGroup value={fastMode} onIonChange={e => setFastMode(e.detail.value)}>
            <IonListHeader>
              <IonLabel>Mode</IonLabel>
            </IonListHeader>

            <IonItem>
              <IonRadio slot="start" value={true}>Fast</IonRadio>
            </IonItem>

            <IonItem>
              <IonRadio slot="start" value={false}>Reliable</IonRadio>
            </IonItem>
          </IonRadioGroup>
        </IonList>) : ""*/}

        <div className="mps-ota-upload-button-wrapper">
          {writeInProgress === false ?
            <IonButton color="main" size="large"
                       onClick={prepareAndWriteOta}
                       disabled={otaFile === undefined}
                       className="mps-ota-upload-button"
            >
              Upload
            </IonButton> :
            <IonButton color="main" size="large"
                       onClick={() => finishOtaAndDisconnect()}
                       disabled={!writeFinished}
                       className="mps-ota-upload-button"
            >
              Finish & Disconnect
            </IonButton>}
        </div>

        {otaResultMessage ? (
          <div className="mps-error-text">
            {otaResultMessage}
          </div>
        ) : ""}

        <IonGrid class="ion-padding-horizontal">
          {writeInProgress === true ? (
            <>
              {writeFinished === false ? (
                <IonRow>
                  <IonCol className="mps-ota-progress">
                    Write progress:
                  </IonCol>
                  <IonCol className="mps-ota-progress">
                    {writeProgress}%
                  </IonCol>
                </IonRow>) : "" }
              <IonRow>
                <IonCol className="mps-ota-progress">
                  Write time:
                </IonCol>
                <IonCol className="mps-ota-progress">
                  {writeTimeSeconds} seconds
                </IonCol>
              </IonRow>
            </>) : "" }
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default OtaUpload;
