import {
  IonContent, IonGrid,
  IonPage, IonRow,
  IonCol, IonToolbar, IonTitle, IonHeader,
  IonButtons, IonButton,
  IonFab, IonFabButton,
  IonIcon,
  useIonViewWillEnter,
} from '@ionic/react';
import {barChartOutline, closeCircleOutline} from "ionicons/icons";
import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store/hooks";

import {useHwBack} from "../hooks/useHwBack";
import {
  isThereConnectedPeripheral,
  getCurrentPeripheral,
  getSelectedModulesIds,
  resetSelectedModules
} from "../store/peripheralsSlice";
import {Module} from "../types";

import './Device.css';
import ModuleCard from "../components/ModuleCard";
import BackButton from "../components/BackButton";

const Device: React.FC = () => {
  const logTag = "Device";

  let history = useHistory();
  const isConnected = useAppSelector(isThereConnectedPeripheral);

  // conditional redirect to Main if no device is connected. Also works in case of disconnect
  useEffect(() => {
    if (!isConnected) {
      history.push('/main');
    }
  }, [isConnected, history]);

  // handling the HW back button
  useHwBack(logTag, null, []);

  const dispatch = useAppDispatch();
  const peripheral = useAppSelector(getCurrentPeripheral);
  const selectedModulesIds = useAppSelector(getSelectedModulesIds);

  const deselectModules = function() {
    dispatch(resetSelectedModules());
  }

  // @note: if there are problems with stale dependencies, add [peripheral] as a second argument
  useIonViewWillEnter(() => {
    dispatch(resetSelectedModules());
  });

  return (
    <IonPage>
      <IonHeader className="mps-layout">
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton />
          </IonButtons>

          <IonTitle>{peripheral.name}</IonTitle>

          { selectedModulesIds.length > 0 ? (
            <IonButtons slot="end">
              <IonButton onClick={deselectModules}>
                <IonIcon icon={closeCircleOutline} size="large" aria-label="Deselect all"></IonIcon>
              </IonButton>
            </IonButtons>
          ) : ''}
        </IonToolbar>
      </IonHeader>

      <IonContent className="mps-layout">
        {selectedModulesIds.length > 0 ? (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton routerLink="/detail/">
              <IonIcon icon={barChartOutline} aria-label="See combined chart"></IonIcon>
            </IonFabButton>
          </IonFab>
        ) : ''}

        <IonGrid>
          <IonRow>
            {peripheral.modules
              ? peripheral.modules.map((module : Module, index) => (
              <IonCol size="6" key={index}>
                <ModuleCard name={module.name}
                            id={module.id}
                            value={module.value}
                            unit={module.unit}
                            icon={module.icon}
                            decimals={module.decimals}
                            highlighted={selectedModulesIds.indexOf(module.id) > -1} />
              </IonCol>
            )) : ''}
            {peripheral.uartSupport
              ? (<IonCol size="6" key="uart">
                <ModuleCard name="Service"
                            icon="terminal-outline"
                            highlighted={false}
                            href="/uart" />
              </IonCol>) : ""}
            {peripheral.settings && peripheral.settings.length > 0
              ?(<IonCol size="6" key="settings">
              <ModuleCard name="Settings"
                          icon="cog-outline"
                          highlighted={false}
                          href="/settings" />
            </IonCol>) : ''}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Device;
