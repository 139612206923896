import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
  IonCol,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonAlert,
  getPlatforms,
  useIonViewWillLeave, IonInput,
} from '@ionic/react';
import {search, ellipsisHorizontal} from "ionicons/icons";
import {useEffect} from "react";
import {Capacitor} from "@capacitor/core";
import {Device} from "@capacitor/device";
import {App} from "@capacitor/app";
import React, {useState} from 'react';
import {useAppSelector} from "../store/hooks";
import {setContext as setSentryContext} from "@sentry/browser";

import {useBle} from "../hooks/useBle";
import {useHwBack} from "../hooks/useHwBack";
import {useLog} from "../hooks/useLog";
import {getPeripherals} from "../store/peripheralsSlice";
import {log} from "../utils/utils";
import {setDeviceInfo} from "../utils/platforms";
import {appVersion} from "../config";

import './Main.css';
import Peripherals from "../components/Peripherals";



const Main: React.FC = () => {
  const logTag = "Main";

  const {scan, scanInProgress, stopScan, disconnectCurrentPeripheral} = useBle();
  const peripherals = useAppSelector(getPeripherals);
  const [showExitAlert, setShowExitAlert] = useState(false);
  const {logInfo} = useLog(logTag);

  const [prefix, setPrefix] = useState<string>("");

  // init effect
  useEffect(() => {
    logInfo(`initialization. Version ${appVersion}, platforms: ${Capacitor.getPlatform()} / ${getPlatforms()}`);
    Device.getInfo().then(deviceInfo => {
      logInfo("", false, deviceInfo);
      // store device info for later use, if the app needs it
      setDeviceInfo(deviceInfo);
      setSentryContext("device_extra", {
        capPlatform : Capacitor.getPlatform(),
        ionPlatform : getPlatforms(),
        info        : deviceInfo
      });
    });
  }, []);  // [] as deps = run once

  // on back button, either show the "Exit App?" alert, or dismiss it if it's open
  useHwBack(logTag, () => {
    showExitAlert ? setShowExitAlert(false) : setShowExitAlert(true);
  }, [showExitAlert]);


  useIonViewWillLeave(() => {
    log.log(logTag, 'ionViewWillLeave event fired');
    setShowExitAlert(false);

    // @note it is important to provide the dependencies, otherwise the state in this hook might be "stale"
    if (scanInProgress) {
      stopScan();
    }
  }, [scanInProgress, showExitAlert]);



  return (
    <IonPage >
      <IonHeader className="ion-text-center mps-header mps-layout" color="main">
        <IonToolbar className="ion-padding">
          <IonTitle className="ion-margin-top ion-text-nowrap">
            MPS<span className="mps-main-title-long"> system</span> AiO
          </IonTitle>
          <div className="ion-align-items-center ion-justify-content-center ion-margin mps-header-logo">
            <img src="assets/mps-logo-simple.svg" alt="MPS Company Logo" />
          </div>
          <div className="mps-header-button">
            <IonButton className="ion-no-padding" routerLink="/app-settings">
              <IonIcon icon={ellipsisHorizontal} aria-label="Settings"></IonIcon>
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="mps-main-screen mps-layout">
        <IonGrid>
          <IonRow className="ion-align-items-center mps-bg-white">
            <IonCol className="ion-text-end mps-display-flex ion-align-items-center ion-justify-content-end">
              <IonInput className="mps-prefix-input"
                        label="Prefix:"
                        clearInput={true}
                        type="text"
                        inputMode="text"
                        value={prefix}
                        onIonInput={(e) => setPrefix(e.detail.value?.toUpperCase() ?? "")}
                        debounce={200}
              ></IonInput>

              <IonButton color="main" size="default" onClick={() => scan(true, prefix)}>
                {scanInProgress
                  ? (<><IonSpinner name="lines-small" slot="start" /> Stop Scanning</>)
                  : (<><IonIcon slot="start" size="small" icon={search} aria-hidden={true}/> Scan</>)}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <Peripherals peripherals={peripherals} />

        {/*Alert shown on back button, confirming exit*/}
        <IonAlert
          isOpen={showExitAlert}
          onDidDismiss={() => setShowExitAlert(false)}
          header={'Exit the app?'}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              handler: () => { log.log(logTag, 'NOT exiting the app');}
            },
            {
              text: 'Yes',
              handler: async () => {
                log.log(logTag,"Exiting the app");
                await disconnectCurrentPeripheral();
                App.exitApp();
              }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Main;
