import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import isString from "lodash/isString";
import {Module, ModuleValue, Setting, Peripheral, UartLogMsg} from "../types.d";
import {newPeripheral} from "../utils/factories";
import {mergeByProperty, stringifyObject} from "../utils/utils";
import {valueHistorySize} from "../config";
import {AppThunk, RootState} from "./store";

export interface PeripheralsState {
  peripherals           : Array<Peripheral>,
  connectedId           : string,
  selectedModulesIds    : Array<string>,
  selectedTimestamp     : number | null,
  autoAxis              : {
    left                : boolean,
    right               : boolean,
  },
  axisBoundsLeft        : {
    min                 : number | undefined,
    max                 : number | undefined,
  },
  axisBoundsRight       : {
    min                 : number | undefined,
    max                 : number | undefined,
  },
  openSettingId         : string,
}

let initialState: PeripheralsState = {
  peripherals           : new Array<Peripheral>(),
  connectedId           : "",
  selectedModulesIds    : new Array<string>(),
  selectedTimestamp     : null,
  autoAxis              : {left: true, right: true},
  axisBoundsLeft        : {min: undefined, max: undefined},
  axisBoundsRight       : {min: undefined, max: undefined},
  openSettingId         : "",
};

const peripheralsSlice = createSlice({
  name: 'peripherals',

  initialState,

  reducers: {
    // Peripherals
    addPeripheral(state, action : PayloadAction<Peripheral>) {
      state.peripherals = mergeByProperty(
        state.peripherals, [action.payload], "id"
      ) as Array<Peripheral>;
    },

    resetPeripherals(state) {
      state.peripherals = new Array<Peripheral>();
    },

    setConnectedPeripheral(state,  action : PayloadAction<string>) {
      state.connectedId = action.payload;
    },


    // Modules and graphs
    updateModule(state, action : PayloadAction<Module>) {
      const connectedPeripheral = findCurrentPeripheral(state.peripherals, state.connectedId);
      if (!connectedPeripheral || !connectedPeripheral.modules) {
        return;
      }

      const moduleIndex = findIndex(connectedPeripheral.modules, ["id", action.payload.id]);
      if (moduleIndex === -1) {
        return;
      }

      connectedPeripheral.modules[moduleIndex] = action.payload;
    },

    updateModuleValue(state, action : PayloadAction<{id : string, value : number}>) {
      // console.log(`updateModuleValue: ${action.payload.moduleId}, ${action.payload.value.timestamp} : ${action.payload.value.value}`);

      let connectedPeripheral = findCurrentPeripheral(state.peripherals, state.connectedId);
      if (!connectedPeripheral || !connectedPeripheral.modules) {
        return;
      }

      let module = find(connectedPeripheral.modules, ["id", action.payload.id]);
      if (!module) {
        return;
      }

      module.value = action.payload.value;
      module.values = addValueToValues(module.values, action.payload.value);
    },

    toggleSelectedModule(state,  action : PayloadAction<string | undefined>) {
      if (action.payload === undefined) {
        return;
      }

      const moduleIndex = action.payload;

      if (state.selectedModulesIds.indexOf(moduleIndex) > -1) {
        state.selectedModulesIds.splice(state.selectedModulesIds.indexOf(moduleIndex), 1);
        return;
      }

      if (state.selectedModulesIds.length === 2) {
        state.selectedModulesIds = [state.selectedModulesIds[1], moduleIndex];
        return;
      }

      state.selectedModulesIds.push(moduleIndex);
    },

    resetSelectedModules(state) {
      state.selectedModulesIds = new Array<string>();
    },

    selectTimestamp(state, action : PayloadAction<number | null>) {
      state.selectedTimestamp = action.payload;
    },

    setAutoAxis(state, action : PayloadAction<{left: boolean, right: boolean}>) {
      state.autoAxis = action.payload;
    },

    setAxisBoundsLeft(state, action: PayloadAction<{min: number | undefined, max: number | undefined}>) {
      state.axisBoundsLeft = action.payload;
    },

    setAxisBoundsRight(state, action: PayloadAction<{min: number | undefined, max: number | undefined}>) {
      state.axisBoundsRight = action.payload;
    },


    // Settings
    // updates the whole Setting
    updateSetting(state, action : PayloadAction<Setting>) {
      const connectedPeripheral = findCurrentPeripheral(state.peripherals, state.connectedId);
      if (!connectedPeripheral || !connectedPeripheral.settings) {
        return;
      }

      const settingIndex = findIndex(connectedPeripheral.settings, ["id", action.payload.id]);
      if (settingIndex === -1) {
        return;
      }

      connectedPeripheral.settings[settingIndex] = action.payload;
    },

    // updates just the current value of a Setting (e.g. after characteristic read)
    updateSettingValue(state, action : PayloadAction<{id : string, value : number | string }>) {
      // console.log(`updateModuleValue: ${action.payload.moduleId}, ${action.payload.value.timestamp} : ${action.payload.value.value}`);

      let connectedPeripheral = findCurrentPeripheral(state.peripherals, state.connectedId);
      if (!connectedPeripheral || !connectedPeripheral.settings) {
        return;
      }

      let setting = find(connectedPeripheral.settings, ["id", action.payload.id]);
      if (!setting) {
        return;
      }

      setting.value = action.payload.value;
    },

    // updates just the "info" property of a Setting (e.g. after Info descriptor read)
    updateSettingInfo(state, action : PayloadAction<{id : string, info : string }>) {
      let connectedPeripheral = findCurrentPeripheral(state.peripherals, state.connectedId);
      if (!connectedPeripheral || !connectedPeripheral.settings) {
        return;
      }

      let setting = find(connectedPeripheral.settings, ["id", action.payload.id]);
      if (!setting) {
        return;
      }

      setting.info = action.payload.info;
    },

    // set currently open setting (for showing write modal)
    setOpenSettingId(state, action : PayloadAction<string>) {
      state.openSettingId = action.payload;
    },


    // UART
    addUartLogMsg(state, action : PayloadAction<UartLogMsg>) {
      let connectedPeripheral = findCurrentPeripheral(state.peripherals, state.connectedId);
      if (!connectedPeripheral || !connectedPeripheral.uartSupport) {
        return;
      }

      if (connectedPeripheral.uartLog === undefined) {
        connectedPeripheral.uartLog = new Array<UartLogMsg>();
      }

      connectedPeripheral.uartLog.push(action.payload);
    },

    setUartNotifying(state, action : PayloadAction<boolean>) {
      const connectedPeripheral = findCurrentPeripheral(state.peripherals, state.connectedId);
      if (!connectedPeripheral) {
        return;
      }

      connectedPeripheral.uartNotifying = action.payload;
    },
  }
});

// helper to generate a timestamp for value (received from BLE notifications) and insert new entry into a
// list of values for a specific Module
const addValueToValues = (values : Array<ModuleValue> | undefined, value : number) => {
  let timestamp = 0;
  if (values === undefined || values.length === 0) {
    values = new Array<ModuleValue>();
  } else {
    timestamp = values[values.length - 1].timestamp + 1;
  }

  if (values.length > valueHistorySize) {
    values.shift();
  }
  values.push({timestamp, value});
  return values;
};

// just a helper to find current connected peripheral in the list of peripherals
const findCurrentPeripheral = (peripherals : Array<Peripheral>, connectedId : string) => {
  return isString(connectedId)
    ? find(peripherals, ["id", connectedId]) || false
    : false;
};

// ##### Selectors
// Peripherals
export const getPeripherals = (state : RootState) => state.peripherals.peripherals;
export const getConnectedId = (state : RootState) => state.peripherals.connectedId;
export const getCurrentPeripheral = createSelector(
  getPeripherals, getConnectedId,
  (peripherals, connectedId) =>
    findCurrentPeripheral(peripherals, connectedId) || newPeripheral({ id : ""})
);
export const isThereConnectedPeripheral = createSelector(
  getConnectedId,(connectedId) : boolean => isString(connectedId) && connectedId.length > 0
);

// Modules and graphs
export const getCurrentModules = createSelector(
  getCurrentPeripheral,
  (currentPeripheral) => get(currentPeripheral, "modules", [])
);
export const getSelectedModulesIds = (state : RootState) => state.peripherals.selectedModulesIds;
export const getSelectedModules = createSelector(
  getCurrentModules, getSelectedModulesIds,
  (allModules, selectedIds) => allModules.filter(
    (module) => (selectedIds.indexOf(module.id) !== -1)
  )
)
export const getSelectedTimestamp = (state : RootState) => state.peripherals.selectedTimestamp;
export const getAutoAxis = (state : RootState) => state.peripherals.autoAxis;
export const getAxisBoundsLeft = (state : RootState) => state.peripherals.axisBoundsLeft;
export const getAxisBoundsRight = (state : RootState) => state.peripherals.axisBoundsRight;

// Settings
export const getOpenSettingId = (state : RootState) => state.peripherals.openSettingId;
export const getCurrentSettings = createSelector(
  getCurrentPeripheral,
  (currentPeripheral) => get(currentPeripheral, "settings", [])
);
// get the Setting which is currently open, i.e. its write modal is visible
export const getOpenSetting = createSelector(
  getCurrentSettings, getOpenSettingId,
  (settings, openSettingId) => find(settings, ["id", openSettingId])
);

// UART
export const getCurrentUartLog = createSelector(
  getCurrentPeripheral,
  (currentPeripheral) => get(currentPeripheral, "uartLog", [])
);


// ##### Thunks
// adding UART log message - generating timestamp, making sure it's stringified
export const uartLogMsg = (type: string, msg: string): AppThunk =>
  async (dispatch, getState) => {

    // just to be really sure it's a string
    const {objectMsg, objectRest} = stringifyObject(msg);

    // generate timestamp for this logItem
    const logItem = { type, msg: objectMsg, timestamp : Date.now()};

    // add the logItem to uartLogMsgs in store of the connected peripheral
    dispatch(addUartLogMsg(logItem));
  };

// #### Default exports (actions, reducer)
export const {
  // Peripherals
  addPeripheral,
  resetPeripherals,
  setConnectedPeripheral,

  // Modules and graphs
  updateModule,
  updateModuleValue,
  toggleSelectedModule,
  resetSelectedModules,
  selectTimestamp,
  setAutoAxis,
  setAxisBoundsLeft,
  setAxisBoundsRight,

  // Settings
  updateSetting,
  updateSettingValue,
  updateSettingInfo,
  setOpenSettingId,

  // UART
  addUartLogMsg,
  setUartNotifying,
} = peripheralsSlice.actions;

export default peripheralsSlice.reducer;
