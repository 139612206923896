import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  IonPopover,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import {ellipsisHorizontal} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store/hooks";

import {useBle} from "../hooks/useBle";
import {useHwBack} from "../hooks/useHwBack";
import {useLog} from "../hooks/useLog";
import {useValidations} from "../hooks/useValidations";
import {
  getCurrentPeripheral, isThereConnectedPeripheral, setOpenSettingId, getOpenSetting, getCurrentSettings
} from "../store/peripheralsSlice";
import {toast} from "../store/logSlice";
import {Setting, SettingTypes} from "../types.d";
import {log} from "../utils/utils";

import BackButton from "../components/BackButton";

const Settings: React.FC = () => {
  const logTag = "Settings";

  const peripheral = useAppSelector(getCurrentPeripheral);
  const allSettings = useAppSelector(getCurrentSettings);
  const openSetting = useAppSelector(getOpenSetting);
  const dispatch = useAppDispatch();
  const {logError} = useLog(logTag);
  const {readAllSettings, writeSetting, readInfoDescriptor} = useBle();
  const {
    validationMessage, setValidationMessage, clearValidationMessage, validateString, validateNumber,
    validateBoolean
  } = useValidations();

  const [valueToWrite, setValueToWrite] = useState<string>("");
  const [showOtaPopover, setShowOtaPopover] = useState<{open: boolean, event: Event | undefined}>({
    open: false,
    event: undefined,
  });

  const isConnected = useAppSelector(isThereConnectedPeripheral);
  let history = useHistory();

  // conditional redirect to Main if no device is connected. Also works in case of disconnect
  useEffect(() => {
    if (!isConnected) {
      history.push('/main');
    }
  }, [isConnected, history]);

  // handling the back button
  useHwBack(logTag, () => {
    openSetting === undefined ? history.goBack() : closeModal();
  }, [openSetting]);


  // this is the only place where settings are read - on enter. @note this will also fire when going back
  // from OtaUpload, which is OK, but can maybe generate descriptor errors
  // @note allSettings fixes an issue when user reloads page while in Settings, reconnects and goes to
  // Settings - the hook would be called with empty settings then (stale data)
  useIonViewWillEnter(() => {
    log.log(logTag,"entering, init, reading all Settings");
    // this will read all the descriptors of Settings (only once for connected peripheral; except Info
    // descriptor) and then read all the values of Settings every time user enters Settings
    readAllSettings();
  }, [allSettings]);

  // opening and closing the setting write modal
  const openModal = async (setting: Setting) => {
    // the modal is opened only if the setting is writable. If the setting is not readable or hasn't been
    // read yet, we don't have any value to prefill the inputs with
    if (!setting.writable) {
      return;
    }

    // read the value of Info descriptor first. Don't wait for it
    readInfoDescriptor(peripheral.id, setting);

    // open the modal, pre-populate value field
    dispatch(setOpenSettingId(setting.id));
    setValueToWrite(setting.value?.toString() ?? "");
  }

  const closeModal = () => {
    dispatch(setOpenSettingId(""));
    clearValidationMessage();
  }

  // validations of values before write
  const validateBeforeWrite = () : boolean => {
    clearValidationMessage();

    if (!openSetting) {
      log.log(logTag, "validateBeforeWrite: openSetting doesn't exist, how could this happen?");
      setValidationMessage(`Something went wrong. Please send logs`);
      return false;
    }

    switch (openSetting.type) {
      case SettingTypes.boolean:
        return validateBoolean(valueToWrite);
      case SettingTypes.string:
        return validateString(valueToWrite);
      case SettingTypes.number:
        return validateNumber(valueToWrite, openSetting.min, openSetting.max);
      default:
        logError(`Setting: write failed - unknown setting type`, false, {
          settingName : openSetting.name, settingId : openSetting.id, settingType : openSetting.type
        });
        setValidationMessage(`Something went wrong. Please send logs`);
        return false;
    }
  }

  // the actual write
  const doWrite = async () => {
    if (!validateBeforeWrite() || !openSetting) {
      return;
    }
    log.log(logTag, "Write: validation passed");

    // transform the value to bytes and send them over BLE to the peripheral
    try {
      await writeSetting(peripheral.id, openSetting, valueToWrite);
      dispatch(toast(`"${openSetting.name}": successful write!`));
      closeModal();

    } catch (error) {
      if (error instanceof Error) {
        setValidationMessage(error.message);

      } else {
        setValidationMessage(`Unexpected error - write failed!`);
      }

      dispatch(toast("Write failed"));
      logError(`Setting: write failed!`, false, {
        error, valueToWrite : valueToWrite, settingName : openSetting.name, settingId : openSetting.id
      });
    }
  };

  return (
    <IonPage>
      <IonHeader className="mps-layout">
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton />
          </IonButtons>

          {peripheral.otaSupport ?
          (<IonButtons slot="end">
            <IonButton className="ion-no-padding" onClick={e => setShowOtaPopover({open: true, event: e.nativeEvent})}>
              <IonIcon slot="icon-only" icon={ellipsisHorizontal} aria-label="More options" />
            </IonButton>
            <IonPopover
              isOpen={showOtaPopover.open}
              event={showOtaPopover.event}
              onDidDismiss={e => setShowOtaPopover({open: false, event: undefined})}
              side="bottom"
              alignment="end"
              reference="trigger"
              className="mps-settings-popover"
            >
              <div>
                <IonItem routerLink="/ota-upload/" onClick={e => setShowOtaPopover({open: false, event: undefined})}>
                  OTA Update
                </IonItem>
              </div>
            </IonPopover>
          </IonButtons>) : ""}

          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="mps-layout">

        <IonList>
          {peripheral.settings
            ? peripheral.settings.map((item : Setting, index) =>(
            <IonItem key={index}
                     onClick={() => { openModal(item) }}
                     className={"mps-setting-item" + (item.writable ? " mps-cursor-pointer" : "")}
            >

              <IonGrid className="ion-no-padding">
                <IonRow className="ion-justify-content-center ion-align-items-center">
                  <IonCol size="1" className="ion-text-center ion-padding-end">
                    <IonIcon src={"assets/svg/" + (item.icon ?? "settings-outline") + ".svg"} size="small" aria-hidden={true} />
                  </IonCol>
                  <IonCol size="11" className="ion-padding-vertical ion-padding-start">
                    <div className="">
                      <IonLabel className="mps-setting-name">
                        {item.name}
                      </IonLabel>
                    </div>
                    <div className="">
                      {
                        // @ts-ignore
                        item.type === SettingTypes.number ? item.value?.toFixed(item.decimals) : item.value
                      }
                      &thinsp;<span className="mps-setting-item-unit">{item.unit}</span>
                    </div>
                  </IonCol>

                </IonRow>
              </IonGrid>
            </IonItem>
          )) : 'No Settings available for this device!'}
        </IonList>

        {/*The write modal*/}
        <IonModal isOpen={openSetting !== undefined && isConnected}
                  showBackdrop={true}
                  backdropDismiss={true}
                  onDidDismiss={() => closeModal()}
                  className="mps-settings-modal"
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <IonIcon src={"assets/svg/" + (openSetting?.icon ?? "settings-outline") + ".svg"}
                         size="small"
                         className="ion-margin-end"
                         aria-hidden={true}
                />
                {openSetting?.name}
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-justify-content-start ion-padding">
            {openSetting?.unit ? (
              <IonText className="mps-settings-modal-unit">{openSetting?.unit.trim()}</IonText>
            ) : ""}

            <IonItem>
              {(() => {
                switch (openSetting?.type) {
                  default:
                  case SettingTypes.number: return (<>
                    <IonInput label="Enter number:"
                              labelPlacement="stacked"
                              clearInput={true}
                              type="tel"
                              inputMode="numeric"
                              min={`${openSetting?.min}`}
                              max={`${openSetting?.max}`}
                              value={valueToWrite}
                              onIonInput={(e) => setValueToWrite(`${e.detail.value}` || "")}
                              helperText={
                                (openSetting?.min !== null ? (`min: ${openSetting?.min}`) : "") +
                                (openSetting?.min !== null && openSetting?.max !== null ? ", " : "") +
                                (openSetting?.max !== null ? (`max: ${openSetting?.max}`) : "")
                              }
                    ></IonInput></>);
                  case SettingTypes.string: return (
                    <IonInput label="Enter a string:"
                              labelPlacement="stacked"
                              clearInput={true}
                              type="text"
                              inputMode="text"
                              value={valueToWrite}
                              onIonInput={(e) => setValueToWrite(`${e.detail.value}` || "")}
                              helperText=" "
                    ></IonInput>);
                  case SettingTypes.boolean: return (<div>
                      <IonToggle labelPlacement="end"
                                 color="main"
                                 checked={valueToWrite === "1"}
                                 onIonChange={(e) =>
                                   setValueToWrite((e.detail.checked && e.detail.checked === true) ? "1" : "0")
                                 }
                      >Switched on = 1 = true</IonToggle>
                  </div>);
                }
              })()}

            </IonItem>
            <div className="mps-error-text">
              {validationMessage}
            </div>
            <div className="ion-text-right">
              <IonButton onClick={() => closeModal()}
                         color="light">Cancel</IonButton>

              <IonButton color="main"
                         hidden={!openSetting?.writable}
                         onClick={doWrite}
              >Save</IonButton>
            </div>

            {openSetting?.info ? (
              <div className="ion-padding-top ion-padding-horizontal">
                <h4>Info:</h4>
                <div dangerouslySetInnerHTML={{__html: openSetting.info}} />
              </div>) : ""}
          </IonContent>
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default Settings;
