import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  BleClient, BleDescriptor, BleService, ScanResult, numbersToDataView
} from "@capacitor-community/bluetooth-le";
import throttle from "lodash/throttle";
import keyBy from "lodash/keyBy";
import find from "lodash/find";
import {useDiagnostic} from "./useDiagnostic";
import {useLog} from "./useLog";

// store-related stuff
import {
  Peripheral,
  Module,
  Setting,
  ModuleBase,
  ParsedDescriptors,
  MpsManufacturerData,
  MpsConnectionError
} from "../types.d";
import {newPeripheral} from "../utils/factories";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {toast, peripheralToSentryContext} from "../store/logSlice";
import {
  addPeripheral, resetPeripherals, setConnectedPeripheral, getCurrentPeripheral,
  updateModule, updateModuleValue,
  updateSetting, updateSettingInfo, setOpenSettingId,
  uartLogMsg, setUartNotifying,
} from "../store/peripheralsSlice";
import {setWriteProgress, setWriteTime} from "../store/writeProgressSlice";
import {fakePeripherals, fakeScannedPeripherals} from "../store/fakeData";

// our custom BLE utils
import {log, debounceById, waitFor} from "../utils/utils";
import {isWeb, isNativeApp} from "../utils/platforms";
import {utf8StringToBytes} from "../utils/conversions";
import {
  canUseWrite,
  canUseWriteWithoutResponse,
  convertValueToBytes,
  isNotifiable,
  isReadable,
  parseManufacturerData,
  parseConnectedPeripheral,
  parseValues,
  parseUartNotify,
  parseDescriptors,
  parseInfoDescriptor,
  formatToSettingType
} from "../utils/bleParsers";
import {
  connectionTimeoutS,
  writeTimeoutS,
  displayFakeDataOnly,
  modulesServiceUuid,
  settingsServiceUuid,
  descriptorsUuids, descInfoFull,
  otaServiceUuid, otaControlCharaUuid, otaDataCharaUuid,
  uartServiceUuid, uartTxNotifyCharaUuid, uartRxWriteCharaUuid
} from "../config";


export function useBle() {
  const logTag = "useBle";

  let history = useHistory();
  const {diagnose} = useDiagnostic();
  const {logInfo, logError} = useLog(logTag);

  const [scanInProgress, setScanInProgress] = useState<Boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isConnectingTo, setIsConnectingTo] = useState<string>("");

  const currentPeripheral = useAppSelector(getCurrentPeripheral);
  const dispatch = useAppDispatch();

  let foundDevicesList = new Array<string>();

  // a way to mute the next toast saying "peripheral disconnected" - this toast is emitted by onDisconnect
  // callback and cannot be controlled in another fashion
  let muteNextDisconnect = false;

  useEffect( () => {
    return function cleanup() {
      console.log(`[${logTag}]`, "============= CLEANUP =============");
      if (scanInProgress) {
        stopScan().then(() => {
          log.log(logTag, "Scan interrupted.");
        });
      }
    }
  }, [scanInProgress] );


  const scan = async (reportDuplicates : boolean, prefix : string) => {
    if (scanInProgress) {
      console.log(`[${logTag}]`, "Scan in progress, stopping");
      return stopScan();
    }

    setScanInProgress(true);

    if (displayFakeDataOnly) {
      fakeScanDevices(reportDuplicates);
      return;
    }

    // if the app runs on real device we first need to diagnose whether BLE is available, location is on etc
    // @note: BleClient.initialize checks for permissions, but if the permissions are removed by Android
    // automatically over time, initialization might fail. See feature request:
    // https://github.com/capacitor-community/bluetooth-le/issues/487
    const diagnosticResult = await diagnose();
    log.log(logTag, "Result of diagnosis: " + diagnosticResult);

    // the scan is not possible - BT is unavailable etc
    if (!diagnosticResult) {
      setScanInProgress(false);
      return;
    }

    try {
      // initialize also diagnoses all the permissions and stuff, so that's a secondary level of protection
      // after diagnose()
      await BleClient.initialize({androidNeverForLocation: true});

    } catch (error) {
      // this can also happen if permissions are not granted I guess
      logError("Failed to initialize BLE client", true, error);
      setScanInProgress(false);
      return;
    }

    // Android, iOS: we can do actual scanning
    if (isNativeApp()) {
      // if the current peripheral is connected, disconnect it, but don't wait for it. Otherwise scan would
      // just hide the device and it would be stuck in connected state. For requestDevice, this might not
      // matter
      disconnectCurrentPeripheral();

      await scanDevices(reportDuplicates, prefix);

    // Web platform: we have to show browser-native device request modal
    } else {
      requestDevice(prefix);
    }
  };

  const requestDevice = async (prefix : string) => {
    logInfo(`Requesting a device. Prefix: ${prefix || 'empty'}`);

    try {
      // peripheral: {deviceId, name}
      const peripheral = await BleClient.requestDevice({
        namePrefix        : prefix,
        optionalServices  : [modulesServiceUuid, settingsServiceUuid, otaServiceUuid, uartServiceUuid],
      });
      logInfo(`User chose to pair with peripheral:`,false, peripheral);
      connectOrOpen(peripheral.deviceId, peripheral.name || "(no name)");

    } catch (error : any) {
      logInfo("Failed to request a device", false, error);
      setScanInProgress(false);
    }
  }

  // we need to extract name, id, rssi and parse manufacturerData from ScanResult
  // @note the advertisement is already pre-parsed for us by capacitor BLE plugin
  const processScannedPeripheral = (scanResult : ScanResult) => {
    // Debounce mechanism prevents flooding of store/react render by new values. The method only
    // returns "true" for specific ID after a debounceTime time has passed
    if (!debounceById(scanResult.device.deviceId)) {
      return;
    }

    // just for convenience
    const peripheralId = scanResult.device?.deviceId ?? "0";
    const peripheralName = scanResult.localName;

    // if the key in manufacturerData is not "767" = 0FFF = Silicon Laboratories, we're not interested
    if (!scanResult?.manufacturerData?.["767"]) {
      log.log(logTag, `Scan result ${peripheralName} (${peripheralId}): unsupported manufacturer data, discarding`);
      return;
    }

    // parse manufacturerData (DataView) into something we can display. If we can't parse it correctly, this
    // is not a device we're looking for
    let manufacturerData : MpsManufacturerData;

    try {
      manufacturerData = parseManufacturerData(scanResult);

    } catch (error) {
      log.log(logTag, `Scan result ${peripheralName} (${peripheralId}): unable to parse manufacturer data, discarding`);
      logInfo("", false, error);
      return;
    }

    // Everything fits - let's store this peripheral.
    // Just some logging first
    if (foundDevicesList.indexOf(peripheralId) === -1) {
      foundDevicesList.push(peripheralId);
      logInfo(`Scan result ${peripheralName} (${peripheralId}): supported device found`);

    } else {
      log.log(logTag,`Scan result ${peripheralName} (${peripheralId}): supported device`);
    }

    // add to store. After connect(), this is merged by ID with connected device's data
    dispatch(addPeripheral(newPeripheral({
      id : peripheralId, name : peripheralName, rssi : scanResult.rssi, manufacturerData
    })));
  };

  const scanDevices = async (reportDuplicates : boolean, prefix : string) => {
    logInfo(`Scan started. Prefix: ${prefix || 'empty'}`);

    dispatch(resetPeripherals());
    foundDevicesList = new Array<string>();

    try {
      await BleClient.requestLEScan(
        {
          namePrefix        : prefix,
          allowDuplicates   : reportDuplicates
        },
        // we need to extract name, id, rssi and parse manufacturerData from ScanResult
        (scanResult : ScanResult) => processScannedPeripheral(scanResult)
      );

    } catch (error) {
      logError(`Scanning failed!`, false, error);
      dispatch(toast(`Scanning failed! Please try again or check the logs`));
      setScanInProgress(false);
    }
  };

  const stopScan = async () => {
    // @note: seems like we can't check if scan is in progress first, as the state can become stale somehow

    if (isWeb()) {
      setScanInProgress(false);
      return;
    }

    try {
      await BleClient.stopLEScan();
      logInfo(`Scan stopped`);
    } catch (e) {
      // @note: this probably doesn't have to bother the user and we don't need it in Sentry either
      logInfo("Minor error: failed to stop BLE scan.", false);
      log.log(logTag, e);
    } finally {
      setScanInProgress(false);
    }
  };

  const connectOrOpen = async (peripheralId : string, peripheralName : string) => {
    log.log(logTag, `startConnection: ${peripheralName} - ${peripheralId}`);

    if (isConnecting) {
      log.log(logTag, "Already connecting!");
      return;
    }

    // stop the scan as apparently it is not stopped automatically
    stopScan();
    setIsConnecting(true);
    setIsConnectingTo(peripheralId);


    // this means we already have connection with this specific device. Just redirect then. Saves battery
    if (currentPeripheral.id === peripheralId) {
      logInfo( `Connect: peripheral already connected, just redirecting to ${peripheralName} - ${peripheralId}`);
      goToConnected(currentPeripheral.id);
      return;
    }

    // So we're not connected to this peripheral. Always try to disconnect it first for safety measures.
    // This is especially important on android:
    // https://github.com/capacitor-community/bluetooth-le#connection-fails-on-android
    await disconnect(peripheralId);

    // if we're currently connected to any other peripheral, disconnect that as well
    if (currentPeripheral.id !== peripheralId && currentPeripheral.id !== "") {
      // @note we don't wait for this disconnect
      disconnect(currentPeripheral.id);
    }

    connect(peripheralId, peripheralName);
  };

  // @note this callback is also called when the peripheral disconnects any time after connection
  const onDisconnect = (peripheralId : string, peripheralName : string) => {
    logInfo(`Disconnected: ${peripheralName} (${peripheralId})`, !muteNextDisconnect);
    dispatch(setConnectedPeripheral(""));
    setIsConnecting(false);
    setIsConnectingTo("");
    muteNextDisconnect = false;
    // the app will redirect to Main with the help of useEffects in components
  }

  const connect = async (peripheralId : string, peripheralName : string) => {
    logInfo(`Connecting to ${peripheralName} (${peripheralId})`);

    if (displayFakeDataOnly) {
      fakeConnect();
      return;
    }

    dispatch(toast(`Connecting to: ${peripheralName || '(no name)'}`, connectionTimeoutS * 1000));

    // so that it's available after try/catch block too
    let connectedPeripheral : Peripheral;

    // the giant try/catch block so that we don't have to repeat logging etc
    try {

      // #### connect to device
      try {
        await BleClient.connect(
          peripheralId,
          (id) => onDisconnect(id, peripheralName),
          { timeout : connectionTimeoutS * 1000}
        );

      // timeouts end up here, also "unsupported device" etc
      } catch (error) {
        throw new MpsConnectionError("Error while connecting to peripheral", {
          error, peripheralId, peripheralName, timeout : `${connectionTimeoutS}s`
        });
      }

      // #### get all the services of the peripheral so that we can find its modules and settings
      let services : Array<BleService>;
      try {
        // this seems to be required when testing on Android 13, otherwise getServices() would return empty
        // array (on Android 11 and iOS 15 it doesn't). Also, discoverServices is not available on Web
        if (!isWeb()) {
          await BleClient.discoverServices(peripheralId);
        }

        services = await BleClient.getServices(peripheralId);

      } catch (error) {
        throw new MpsConnectionError("Error while discovering services of the peripheral", {
          error, peripheralId, peripheralName
        });
      }

      // no services = no modules and settings = no possible interation with the peripheral
      if (services.length === 0) {
        throw new MpsConnectionError("Unable to discover services of this peripheral!", {
          peripheralId, peripheralName
        });
      }

      // #### get the MTU of the device. On Web or in case of error, this results in 0 but doesn't throw
      const actualMtu : number = await getActualMtu(peripheralId);

      // #### inform user about successful connection
      dispatch(toast(`Connected to ${peripheralName || '(no name)'}`));

      // #### process the services to Modules and Settings and determine UART and OTA support
      try {
        connectedPeripheral = parseConnectedPeripheral(peripheralId, peripheralName, services, actualMtu);
        logInfo(`Connected to peripheral: ${peripheralName} (${peripheralId})`);
        log.log(logTag, connectedPeripheral);

      } catch (error) {
        throw new MpsConnectionError("Error while parsing connected peripheral", {
          error, peripheralId, peripheralName
        });
      }

      // without any modules AND settings, user would have nothing to interact with, so this is an error
      if ((!connectedPeripheral.modules || connectedPeripheral.modules.length === 0)
        && (!connectedPeripheral.settings || connectedPeripheral.settings.length === 0))
      {
        throw new MpsConnectionError("Error: this peripheral has no Modules and Settings", {
          peripheralId, peripheralName, ...peripheralToSentryContext(connectedPeripheral)
        });
      }

      // #### add this peripheral to store so that other parts of the app have access to it
      dispatch(addPeripheral(connectedPeripheral));

      // reset any old open Setting write modal from previous connections
      dispatch(setOpenSettingId(""));

      // for good measure and hopefully a re-render
      await waitFor(100);

      // #### redirect the app to the Device page of current peripheral
      goToConnected(connectedPeripheral.id);

      // weird way of forcing Ionic/React/React-router to actually load the page immediately
      await waitFor(100);

    } catch (error) {
      if (error instanceof MpsConnectionError) {
        logError(error.message, true, error.extraData);

      } else {
        logError(`Error while connecting`, true, {
          error, peripheralId, peripheralName
        });
      }

      setIsConnecting(false);
      setIsConnectingTo("");

      // the error might've happened after connection and we don't want the peripheral to be stay connected
      disconnect(peripheralId, true);
      return;
    }

    // #### this is here mainly to fix problem with Promise.all processing possibly undefined modules later.
    // Without Modules, the peripheral can still have at least Settings.
    // @note: we do not include this code in the previous massive try/catch so that if reading of descs
    // fails, there's still some chance to interact with what's left
    if (!connectedPeripheral.modules || connectedPeripheral.modules.length === 0) {
      logError(`Peripheral has no Modules`, false, {
        ...peripheralToSentryContext(connectedPeripheral)
      });
      return;
    }

    // #### read descriptors, read values & subscribe for notifications of our Modules immediately
    // await Promise.all() is redundant now, but might be handy if we decide to wait for this
    try {
      await Promise.all(connectedPeripheral.modules.map(async (module : Module) => {
        const parsedDescriptors = await readAllDescriptors(connectedPeripheral.id, module);
        module = { ...module, ...parsedDescriptors, descriptorsRead : true };
        dispatch(updateModule(module));

        // now we can read the current value of this module and start listening for its notifications
        // @note: we're not waiting for it
        readModule(connectedPeripheral.id, module);
        startModuleNotification(connectedPeripheral.id, module);
      }));

    } catch (error) {
      logError(`Error while reading descriptors and Modules`, false, {
        error, ...peripheralToSentryContext(connectedPeripheral)
      });
    }

    // @note we do not read Settings (descs or values) now, they are read every time user enters Settings

  };

  const readAllDescriptors = async (peripheralId : string, moduleBase : ModuleBase)
    : Promise<ParsedDescriptors> => {

    log.log(logTag, `Reading descriptors for module or setting: ${moduleBase.id}`);

    // Filter out any descriptors we don't care about - so that we don't have to read them. Saves time and
    // battery. Note this doesn't include Info descriptor - that is read only when a Setting is opened.
    const relevantDescriptors = moduleBase.descriptors.filter(
      (descriptor : BleDescriptor) => descriptorsUuids.indexOf(descriptor.uuid) !== -1
    );

    const readResult = await Promise.all(relevantDescriptors.map(async (descriptor : BleDescriptor) => {
      try {
        const value = await BleClient.readDescriptor(
          peripheralId, moduleBase.serviceId, moduleBase.id, descriptor.uuid
        );
        return { uuid : descriptor.uuid, value };

      } catch (error) {
        logError(`Error while reading descriptor ${descriptor.uuid}`, false,
          {error, charaId : moduleBase.id, serviceId : moduleBase.serviceId});
        return { uuid : descriptor.uuid, value : "" };
      }
    }));

    const descriptors = keyBy(readResult, "uuid");
    return parseDescriptors(descriptors, moduleBase, dispatch);
  }

  const readInfoDescriptor = async (peripheralId : string, setting : Setting) => {
    // @note: if we wanted to cache Info descriptor value, we'd return from here if setting.info.length > 0

    if (!find(setting.descriptors, ["uuid", descInfoFull])) {
      log.log(logTag, `Setting ${setting.name} (${setting.id}) does not have Info descriptor`);
      return;
    }

    log.log(logTag, `Reading info descriptor for Setting: ${setting.name} (${setting.id})`);

    try {
      const value = await BleClient.readDescriptor(peripheralId, setting.serviceId, setting.id, descInfoFull);
      dispatch(updateSettingInfo({id : setting.id, info : parseInfoDescriptor(value)}));

    } catch (error) {
      logError(`Error while reading info descriptor`, false, {
        error, settingId : setting.id, settingName : setting.name
      })
    }
  }

  const goToConnected = (peripheralId : string) => {
    dispatch(setConnectedPeripheral(peripheralId));
    setIsConnecting(false);
    setIsConnectingTo("");

    if (history.location.pathname !== "/device") {
      history.push("/device");
    }
  };

  const disconnect = async (id : string, silent : boolean = false) => {
    if (!id) {
      return;
    }

    log.log(logTag, "Disconnecting " + id);

    try {
      // @note clearing stuff - we don't wait for disconnect, it might fail and whatnot
      dispatch(setConnectedPeripheral(""));

      // actual disconnect
      muteNextDisconnect = silent;
      await BleClient.disconnect(id);

    } catch (error) {
      logError(`Failed to disconnect`, false, {error, peripheralId : id});
    }
  };

  const disconnectCurrentPeripheral = async () => {
    await disconnect(currentPeripheral.id);
  };

  const readAllSettings = async () => {
    if (displayFakeDataOnly) {
      return;
    }

    if (!currentPeripheral.settings || currentPeripheral.settings.length === 0) {
      logInfo(`Peripheral ${currentPeripheral.id} has no Settings`);
      return;
    }

    logInfo(`Reading all settings (${currentPeripheral?.settings?.length ?? '0'}) for ${currentPeripheral.id}`);

    await Promise.all(currentPeripheral.settings.map(async (setting : Setting) => {
      // first, read all the descriptors - we postponed this operation, it's not done on connect() to save
      // time and battery

      if (!setting.descriptorsRead) {
        const parsedDescriptors = await readAllDescriptors(currentPeripheral.id, setting);
        // don't forget to determine Setting type now, when we have descriptors
        setting = {
          ...setting,
          ...parsedDescriptors,
          type : formatToSettingType(parsedDescriptors.format),
          descriptorsRead : true
        };
      }

      // the characteristic may not be readable - in that case, we only show the descriptors
      if (!isReadable(setting)) {
        log.log(logTag, `Setting [${currentPeripheral.id}] -> "${setting.name}" (${setting.id}) does not have property Read`);
        dispatch(updateSetting(setting));
        return Promise.resolve(setting);
      }

      // now we can read the current value of this setting. Note that readSetting will update the Setting in
      // store together with parsed descriptors
      return readSetting(currentPeripheral.id, setting);
    }));
  };

  const readSetting = async (peripheralId : string, setting: Setting, suppressError : boolean = false)
    : Promise<Setting> => {

    log.log(logTag,`Reading Setting "${setting.name}" (${setting.id})`);

    try {
      const parsedValues = await readChara(peripheralId, setting);
      setting = {...setting, value : parsedValues[0]};
      dispatch(updateSetting(setting));

    } catch (error) {
      // it is expected the read will fail right after some writes which automatically disconnect
      if (suppressError) {
        logInfo(`Error while reading Setting "${setting.name}" (${setting.id})`, false, error);
      } else {
        logError(`Error while reading Setting "${setting.name}" (${setting.id})`, false, error);
      }

    } finally {
      return setting;
    }
  };

  const readModule = async (peripheralId : string, module : Module) => {
    log.log(logTag,`Reading Module "${module.name}" (${module.id})`);

    try {
      const parsedValues = await readChara(peripheralId, module);

      // empty read is a legit use case, e.g. right after OTA. Nothing to display then tho
      if (parsedValues !== null && parsedValues.length === 0) {
        log.log(logTag,`Read: parsing value for Module "${module.name}" (${module.id}) - empty!`, false);
        return;
      }

      // parsing failed (null) or we found something else than number - that isn't allowed for Modules
      if (parsedValues === null || typeof parsedValues[0] !== "number") {
        logError(`Read: parsing value for Module "${module.name}" (${module.id}) failed!`, false, {
          values : parsedValues
        });
        return;
      }

      // @ts-ignore
      parsedValues.forEach((value : number) => {
        // this has to happen like this, because we're assigning "timestamps" to values when they're updated
        dispatch(updateModuleValue({id : module.id, value: value}));
      });

    } catch (error) {
      logError(`Error while reading Module "${module.name}" (${module.id})`, false, error);
    }
  };

  // moduleBase can be a Module or a Setting
  const readChara = async (peripheralId : string, moduleBase : ModuleBase) => {
    // logInfo(`Reading characteristic ${peripheral.id} -> ${moduleBase.id}`);
    if (!isReadable(moduleBase)) {
      throw new Error(`"${moduleBase.name}" (${moduleBase.id}) does not have property Read`);
    }

    const result : DataView = await BleClient.read(peripheralId, moduleBase.serviceId, moduleBase.id);

    const parsedValues = parseValues(moduleBase, result.buffer);
    if (parsedValues === null) {
      throw new Error(`Read: parsing value for "${moduleBase.name}" (${moduleBase.id}) failed!`);
    }

    logInfo(`Read result for "${moduleBase.name}" (${moduleBase.id}): ${parsedValues?.[0]}`, false);
    return parsedValues;
  };

  const writeSetting = async (peripheralId : string, setting : Setting, newValue : string) => {
    const bytes = convertValueToBytes(setting, newValue);

    if (bytes === null) {
      throw new Error(`Write to Setting "${setting.name}" (${setting.id}): unable to parse ${newValue}, ` +
        `format: ${setting.format}`);
    }

    if (canUseWrite(setting)) {
      // also called "write request"
      await BleClient.write(peripheralId, setting.serviceId, setting.id, new DataView(bytes),
        {timeout : writeTimeoutS * 1000});
      logInfo(`Write with response to Setting "${setting.name}" (${setting.id}) was successful: ${newValue}`);

    } else if (canUseWriteWithoutResponse(setting)) {
      // also called "write command"
      await BleClient.writeWithoutResponse(peripheralId, setting.serviceId, setting.id, new DataView(bytes),
        {timeout : writeTimeoutS * 1000});
      logInfo(`Write without response to Setting "${setting.name}" (${setting.id}) was successful: ${newValue}`);

    } else {
      // this should never happen, if the setting isn't writable how have we called this method?
      throw new Error(`Write to Setting "${setting.name}" (${setting.id}): suddenly not writable!`);
    }

    // @note: currently, we don't wait for read, which might or might not be a wanted behavior
    // @note: currently, the device is disconnected automatically after many setting changes, so this
    // might often end up in error. Thus we suppress the error so that Sentry won't get flooded
    if (isReadable(setting)) {
      readSetting(peripheralId, setting, true);
    }
  };


  const startModuleNotification = async (peripheralId : string, module : Module) => {
    // check the properties (permissions) of this characteristic (module)
    if (!isNotifiable(module)) {
      logInfo(`Module "${module.name}" (${module.id}) does not have property Notify`);
      return;
    }

    logInfo(`Start notification for Module "${module.name}" (${module.id})`);

    try {
      BleClient.startNotifications(peripheralId, module.serviceId, module.id, (result : DataView) => {
        if (!debounceById(module.id)) {
          return;
        }

        // the main value parsing logic - format, exponent, decimals
        const parsedValues = parseValues(module, result.buffer);

        // empty notification might be a legit use case, e.g. right after OTA. Nothing to display then tho
        if (parsedValues !== null && parsedValues.length === 0) {
          log.log(logTag, `Notification for Module "${module.name}" (${module.id}): empty!`, true);
          return;
        }

        // parsing failed (null) or we found something else than number - that isn't allowed for Modules
        if (parsedValues === null || typeof parsedValues[0] !== "number") {
          logError(`Notification: parsing value for Module "${module.name}" (${module.id}) failed!`, false);
          return;
        }

        // so we have some numbers in the notification
        log.log(logTag, `Notification for Module "${module.name}" (${module.id}): ${parsedValues[0]}`, true);

        // @note: can it really be only number? maybe check the value types
        dispatch(updateModuleValue({id : module.id, value : parsedValues[0]}));
      });

    } catch (error) {
      logError(`Notification error for Module "${module.name}" (${module.id})`, false, error)
    }
  };

  // @note: currently unused, notifications are stopped automatically on disconnect and we don't have a
  // use case for manual stop
  const stopNotification = async (peripheralId : string, serviceUuid : string, charUuid : string) => {
    log.log(logTag, `Stop notification for ${peripheralId} -> ${serviceUuid} -> ${charUuid}`);

    try {
      await BleClient.stopNotifications(peripheralId, serviceUuid, charUuid);
      logInfo(`Notification stopped for service ${serviceUuid} and characteristic ${charUuid}`);

    } catch (error) {
      logError(`Failed to stop notifications for service`, false, {
        error, serviceId : serviceUuid, characteristicId : charUuid
      });
    }
  };

  const startUartNotification = async (peripheral : Peripheral) => {
    if (!peripheral.uartSupport) {
      logError("Peripheral does not support UART", false);
      return;
    }

    // make sure we only subscribe for notifications once, as per bluetooth-le plugin recommendations
    if (peripheral.uartNotifying) {
      log.log(logTag, `Already listening to UART notifications`);
      return;
    }
    dispatch(setUartNotifying(true));

    logInfo(`Starting UART notifications`);

    try {
      await BleClient.startNotifications(peripheral.id, uartServiceUuid, uartTxNotifyCharaUuid,
        (result : DataView) => {
        // @note: no debouncing here - we don't wanna miss any notification from the device
        const parsedString = parseUartNotify(result.buffer);
        log.log(logTag, `UART notify received: ${parsedString}`);

        // add it to the log that can be shown to the user
        dispatch(uartLogMsg("peripheral", parsedString));
      });

    } catch (error) {
      // @note: display toast true or false? Let's try true, it might not make sense to the user though
      logError(`Notification error for UART`, true, error);
    }
  }

  // just a regular write with response to UART Rx characteristic
  const writeUartCommand = async (peripheralId : string, newValue : string) => {
    const bytes = utf8StringToBytes(newValue);

    if (bytes === null) {
      log.log(logTag, `writeUartCommand: nothing to write, empty bytes`);
      return;
    }

    // @note: potential errors are handled in caller
    await BleClient.write(peripheralId, uartServiceUuid, uartRxWriteCharaUuid, new DataView(bytes));
  }

  const writeInChunks = async (
    peripheral : Peripheral, serviceUuid : string, charaUuid : string, chunkSize : number,
    data : ArrayBuffer, fast : boolean = false
  ) => {
    let chunksToSend = Math.ceil(data.byteLength / chunkSize);
    const chunksTotal = chunksToSend;
    let index = 0;
    const startTime = Date.now();

    // this serves to throttle the amount of dispatches (progress updates) as that might impact performance
    const throttledProgressUpdate = throttle((chunksSent : number) => {
      log.log(logTag, "OTA progress update dispatched");
      dispatch(setWriteProgress(Math.round(chunksSent/chunksTotal*100)));
      dispatch(setWriteTime(Date.now() - startTime));
    }, 1000, { 'leading' : true, 'trailing' : false});

    // recursive function which sends chunks of data until there is nothing more to send
    const writeChunk = async () => {
      if (!chunksToSend) {
        return; // so we don't send an empty buffer
      }

      const chunk = data.slice(index, index + chunkSize);
      index += chunkSize;
      chunksToSend--;

      // @note: for some reason, I can't send this function as a parameter
      fast
        ? await BleClient.writeWithoutResponse(peripheral.id, serviceUuid, charaUuid, new DataView(chunk), { timeout : 3000 })
        : await BleClient.write(peripheral.id, serviceUuid, charaUuid, new DataView(chunk), { timeout : 3000 });

      const chunksSent = chunksTotal - chunksToSend;
      log.log(logTag, `Data chunk ${chunksSent}/${chunksTotal} (${chunk.byteLength}B) written`);

      // inform store about the write progress
      throttledProgressUpdate(chunksSent);

      await writeChunk();
    }

    // send the first chunk. Due to recursion, this will wait until all the chunks are sent
    await writeChunk();

    const duration = Math.round((Date.now() - startTime)/1000);
    log.log(logTag, `Transfer Complete. Duration: ${duration}s`);
  }

  // @note: for preferredMtu, 3B is overhead
  const writeOta = async (
    peripheral : Peripheral, otaFile : ArrayBuffer, preferredMtu : number, withoutResponse : boolean = false
  ) => {
    log.log(logTag, `Write OTA, without response: ${withoutResponse}`);

    if (!peripheral.otaSupport) {
      throw new Error(`The peripheral doesn't support OTA!`);
    }

    if (!otaFile) {
      throw new Error(`Unable to read OTA file.`);
    }

    // write 0x00 to OTA control
    const controlStartByte = numbersToDataView([0]);
    await BleClient.write(peripheral.id, otaServiceUuid, otaControlCharaUuid, controlStartByte);
    logInfo(`OTA: control start byte written successfully`);

    // Chunk data and write to OTA data.
    // @note: "fast mode" means using writeWithoutResponse. On android, we can theoretically use
    // writeWithoutResponse as it's much faster. On iOS, it seems we have to use slow write with response,
    // otherwise it won't work. On Web, it doesn't seem to work either. Nevertheless, this functionality
    // currently isn't enabled
    await writeInChunks(
      peripheral, otaServiceUuid, otaDataCharaUuid, preferredMtu - 3, otaFile, withoutResponse
    );

    // do nothing, wait for user to manually finish the OTA process by sending 0x03 and disconnecting
  };

  const finishOta = async (peripheral : Peripheral) => {
    // write 0x03 to OTA control. note if writeInChunks uses writeWithoutResponse, this must be
    // writeWithoutResponse as well, otherwise ios will weirdly wait for few minutes before finishing this
    const controlEndByte = numbersToDataView([3]);
    await BleClient.write(peripheral.id, otaServiceUuid, otaControlCharaUuid, controlEndByte);
    logInfo(`OTA: control end byte written successfully`);

    // disconnect - @note: we don't do this after all, we just disconnect manually
    // const controlDisconnectByte =  numbersToDataView([4]);
    // await BleClient.write(peripheral.id, otaServiceUuid, otaControlCharaUuid, controlDisconnectByte);
    // log.log(logTag, `OTA: control disconnect byte written successfully`);

    // disconnect manually as the device doesn't seem to restart on its own
    disconnectCurrentPeripheral();
  };

  // @note: this works only on Android and iOS, not on Web
  const getActualMtu = async (peripheralId : string ) : Promise<number> => {
    if (isWeb()) {
      // currently, there's no API to get negotiated MTU in WebBluetooth:
      // https://github.com/WebBluetoothCG/web-bluetooth/issues/383
      return Promise.resolve(0);
    }

    try {
      return await BleClient.getMtu(peripheralId);
    } catch (error) {
      logError("Failed to get MTU of peripheral", false, {error, peripheralId});
      return 0;
    }
  }


  // -----------------------------------------
  // Development & debug purposes only
  const fakeScanDevices = async (reportDuplicates : boolean) => {
    logInfo("Simulating fake scan");
    log.log(logTag, `Fake scanning devices, reporting duplicates: ${reportDuplicates}`);
    logInfo("Scan started...");
    dispatch(resetPeripherals());
    await waitFor(1000);
    processScannedPeripheral(fakeScannedPeripherals[0]);
    await waitFor(500);
    processScannedPeripheral(fakeScannedPeripherals[1]);
    await waitFor(500);
    processScannedPeripheral(fakeScannedPeripherals[2]);
  };

  const fakeConnect = () => {
    logInfo("Simulating fake connection");
    dispatch(addPeripheral(fakePeripherals[0]));
    goToConnected(fakePeripherals[0].id);
  };

  // ------------------------------------------
  // these can be used by hook consumers
  return {
    scan,
    stopScan,
    scanInProgress,

    connectOrOpen,
    disconnect,
    disconnectCurrentPeripheral,
    isConnecting,
    isConnectingTo,

    readAllSettings,
    writeSetting,
    readInfoDescriptor,

    writeOta,
    finishOta,
    getActualMtu,

    startUartNotification,
    writeUartCommand
  };
}
