import {
  IonContent, IonGrid,
  IonPage, IonRow,
  IonCol, IonToolbar, IonTitle, IonHeader,
  IonButtons
} from '@ionic/react';
import find from "lodash/find";
import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../store/hooks";

import {useHwBack} from "../hooks/useHwBack";
import {getSelectedModules, isThereConnectedPeripheral} from "../store/peripheralsSlice";

import ModuleChart from "../components/ModuleChart";
import ModuleTable from "../components/ModuleTable";
import BackButton from "../components/BackButton";

export type ChartValue = {
  timestamp       : number,
  left            : number,
  leftDecimals    : number,
  right?          : number,
  rightDecimals?  : number,
};

const ModuleDetail: React.FC = () => {
  const logTag = "ModuleDetail";

  let history = useHistory();
  const isConnected = useAppSelector(isThereConnectedPeripheral);

  // conditional redirect to Main if no device is connected. Also works in case of disconnect
  useEffect(() => {
    if (!isConnected) {
      history.push('/main');
    }
  }, [isConnected, history]);

  // handling the back button
  useHwBack(logTag, null, []);

  // const isConnected = useAppSelector(isThereConnectedPeripheral);
  const selectedModules = useAppSelector(getSelectedModules);
  const moduleLeft = selectedModules[0];
  const moduleRight = selectedModules[1];

  const [chartValues, setChartValues] = useState<Array<ChartValue>>([]);

  useEffect(() => {

    let tempChartValues = new Array<ChartValue>();
    if (moduleLeft && moduleLeft.values) {
      tempChartValues = moduleLeft.values.map((left) => {

        let right = undefined;

        if (moduleRight && moduleRight.values) {
          right = find(moduleRight.values, (rightValue) => left.timestamp === rightValue.timestamp);
        }

        return {
          timestamp     : left.timestamp,
          left          : left.value,
          leftDecimals  : moduleLeft.decimals,
          right         : right === undefined ? undefined : right.value,
          rightDecimals : moduleRight && moduleRight.decimals ? moduleRight.decimals : 0
        }
      })
    }

    setChartValues(tempChartValues);

  }, [moduleLeft, moduleRight]);

  const contentElement = useRef<HTMLIonContentElement>(null);

  const scrollToTop = function() {
    if (contentElement.current !== null) {
      contentElement.current.scrollToTop(200);
    }
  }


  return (
    <IonPage>
      <IonHeader className="mps-layout">
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton />
          </IonButtons>
          <IonTitle>
            {moduleLeft ? moduleLeft.name : ''}
            {moduleRight ? ' + ' + moduleRight.name : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="mps-layout" ref={contentElement}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <ModuleChart data={chartValues}
                           showRight={moduleRight ? true : false}
                           leftRange={moduleLeft ? {
                             min: moduleLeft.min ?? 0,
                             max: moduleLeft.max ?? 0
                           } : {min: 0, max: 0}}
                           rightRange={moduleRight ? {
                             min: moduleRight.min ?? 0,
                             max: moduleRight.max ?? 0
                           } : undefined}
                           leftDecimals={moduleLeft?.decimals ?? 0}
                           rightDecimals={moduleRight?.decimals ?? 0}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <ModuleTable data={chartValues}
                           scrollCallback={scrollToTop}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default ModuleDetail;
