import React from 'react';
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {
  getSelectedModules,
  selectTimestamp,
  getSelectedTimestamp,
  setAutoAxis,
  setAxisBoundsLeft, setAxisBoundsRight
} from "../store/peripheralsSlice";
import {ChartValue} from "../pages/ModuleDetail";

type ModuleTableProps = {
  data: Array<ChartValue>,
  scrollCallback?: Function,
};

const ModuleTable: React.FC<ModuleTableProps> = ({data, scrollCallback}) => {

  const dispatch = useAppDispatch();

  const selectedModules = useAppSelector(getSelectedModules);
  const selectedTimestamp = useAppSelector(getSelectedTimestamp);

  let timeColSize: string, valueColSize: string;
  if (selectedModules[1]) {
    timeColSize = "2";
    valueColSize = "5";
  } else {
    timeColSize = "4";
    valueColSize = "8";
  }

  const timestampSelected = function(timestamp: number) {
    // Select specific timestamp
    dispatch(selectTimestamp(timestamp));
    // Reset zoom
    dispatch(setAutoAxis({left: true, right: true}));
    dispatch(setAxisBoundsLeft({min: undefined, max: undefined}));
    dispatch(setAxisBoundsRight({min: undefined, max: undefined}));

    if (scrollCallback !== undefined) {
      scrollCallback();
    }
  }

  return (

    <IonGrid className="mps-bg-white mps-module-values-table">
      <IonRow className="mps-module-values-table-header">
        <IonCol size={timeColSize}>Step</IonCol>
        {selectedModules.map((moduleItem) => (
          <IonCol key={moduleItem.id} size={valueColSize} className="ion-text-right"
          >{moduleItem.unit}</IonCol>
        ))}
      </IonRow>

      {[...data].reverse().map((item, index) => (

        <IonRow key={index}
                className={"mps-module-values-table-values " + ((selectedTimestamp === item.timestamp) ? 'is-selected' : '')}
                onClick={() => timestampSelected(item.timestamp)}>
          <IonCol size={timeColSize}>{item.timestamp}</IonCol>
          {item.left !== undefined ? (
            <IonCol size={valueColSize}>{item.left.toFixed(item.leftDecimals)}</IonCol>
          ): ''}

          {item.right !== undefined ? (
            <IonCol size={valueColSize}>{item.right.toFixed(item.rightDecimals)}</IonCol>
          ): ''}
        </IonRow>
      ))}
    </IonGrid>
  );
};

export default ModuleTable;
